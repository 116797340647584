import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConnexionService } from '../services/connexion/connexion.service';
import { NavigationService } from '../services/navigation/navigation.service';
import { StorageService } from '../services/storage/storage.service';
import { SharedService } from '../services/shared/shared.service';
import { Autologin, contactInfo } from '../interfaces/ConnexionOut';
import { isEmailValid } from '../helpers/functions/isEmailValid';
import { NewEmail } from '../pages/new-email.component';

const PROPERTIES_EXCLUSION = ["email", "login"]

@Component({
  selector: 'app-autologin',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './autologin.component.html',
  styleUrl: './autologin.component.css'
})
export class AutologinComponent 
{
  rememberMe!:boolean
  current_service!:number 

  constructor(
    private activatedRoute: ActivatedRoute,
    private connexionService: ConnexionService,
    private storageService: StorageService,
    private navigationService: NavigationService,
    private sharedService: SharedService
  )
  {
    this.current_service = Number(localStorage.getItem("current_service")) || 0
    this.rememberMe = localStorage.getItem("rememberMe") == "1" ? true : false 
  }

  ngOnInit()
  {
    this.activatedRoute.queryParamMap.subscribe((paramsMap:any) => {
      const params:any = paramsMap.params

      const dataMap = new Map()

      for (const key in params) 
      {
        if (Object.prototype.hasOwnProperty.call(params, key))
        { 
          let propertyName
          switch (key) 
          {
            case "ida":
              propertyName = "id_user_partenaire"
            break;
            case "idc":
              propertyName = "id_user_crm"
            break;
            case "idp":
              propertyName = "email_user_contact"
            break;
            case "h":
              propertyName = "horodatage"
            break;
          }

          if(key == "idc") 
          {
            dataMap.set(propertyName, params[key])
          }
          else
          {
            //* En "convertissant la fonction de l'asp, on se retrouve avec des caracctères bizarres en début de chaine"
            dataMap.set(propertyName, this.decryptMail(params[key].trim()).substring(3)) //*
          }
        }
      }
      
      this.connexionService.autologin(dataMap).subscribe((data:Autologin) => {

        //? if( !isEmailValid(data.email) && !isEmailValid(data.login) )
        //? {
        //?   return this.sharedService.openDialog(NewEmail)
        //? }
        //? else if( !isEmailValid(data.email) || !isEmailValid(data.login) )
        //? {
        //?   const valid_email = isEmailValid(data.email) ? data.email : data.login
  
        //?   this.connexionService.updateLogin(valid_email, data.id_user.toString()).subscribe(data => {
        //?     if(!data || data != 1) return
        //?   })
        //? }

          
        if( !data.access_token || !data.refresh_token || !data.id_user )
        {
          alert("Une erreur est survenue lors de la connexion")
          return
        }

        if( this.rememberMe === true )
        {
          localStorage.setItem("rememberMe", "1")
          localStorage.setItem("login", dataMap.get("idp"))
          localStorage.setItem("access_token", data.access_token)
        }
    
        const service_list:any = data.service_eau

        for (const property in data)
        {
          const propertyValue:string = property.toString()

          if(!PROPERTIES_EXCLUSION.includes(property))
          {
            if( property == "service_eau" && data[propertyValue] instanceof Array )
            {
              (data[propertyValue] as contactInfo[]).forEach((data:contactInfo, index:number) => {
                this.storageService.set(`service${index}` , JSON.stringify(data))
              })
            }
            else
            {
              this.storageService.set(property.toString() , data[propertyValue].toString())
            }
          }
        }
  
        const token = data.access_token
        const userCRMId = JSON.parse(data.userCRM).USER_ID

        if( token && this.connexionService.isTokenExpired() == false ) 
        {        
          // ID 474 : Connexion automatique depuis le CRM
          this.sharedService.postTableConnexion(userCRMId, 474, data.id_user).subscribe(() => {
            if(data)
            {
              if(!service_list[this.current_service].copie_message && !service_list[this.current_service].gestion_dossier)
              {
                return this.navigationService.navigateTo('partenariat',[null])
              }
              else
              {
                return this.navigationService.navigateTo('',[null])
              }
            }
          })
        }
      })
    })
  }


  decryptMail(stringToDecrypt: string) 
  {
    if(typeof stringToDecrypt !== "string" ) return stringToDecrypt
    
    let str = stringToDecrypt

    str = str.replace(/dpRiHMnCiZ/g, "0")
    str = str.replace(/FVcJkkkqJE/g, "1")
    str = str.replace(/GRsRkPgeFypZ/g, "2")
    str = str.replace(/DxFKtnRsgH/g, "3")
    str = str.replace(/SRXirPsjgC/g, "4")
    str = str.replace(/NcuBjXbBzK/g, "5")
    str = str.replace(/WWiHJettdV/g, "6")
    str = str.replace(/JkmLVzMvnE/g, "7")
    str = str.replace(/hWyXBNqtYa/g, "8")
    str = str.replace(/vpKaSLppTZ/g, "9")
    str = str.replace(/nVkkNHNrm/g, "=")

    return atob(str)
  }
}

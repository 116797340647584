import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { SharedService } from '../services/shared/shared.service';

@Component({
  standalone: true,
  imports: [CommonModule],
  template: `
  <div class="dialog d-flex">
    <div class="col-4 bg-blue white bold dialog-title">ELARGISSEMENT / RESTRICTION DU PERIMETRE </div>
    <div class="col-8 bg-white blue-dark-1 dialog-content-group">
        <div class="dialog-content">
            <p>Lors de la mise à jour annuelle du nombre de vos abonnés, vous avez indiqué un élargissement ou une restriction du pérmiètre concerné par la convention de partenariat.</p>
            <p>Aussi nous vous prions de noues détailler via le tableau Excel joint [fichier des communes], la liste complète des communes gérées désormais par votre service et comportant le nombre d'abonnés en eau potable, eau brute, assainissement collectif et assainissement non collectif (en fonction des compétences gérées).</p>
            <p>Nous vous remercions de nous mentionner les éventuelles communes qui seraient gérées en Délégation de Service Public (DSP) et nous préciser pour quelle(s) compétence(s) et par quel(s) service(s).</p>
            <button class="dimensionnement-perimetre" (click)="download()">Télécharger le fichier des communes</button>
            
            <div class="mt-5 float-end">
                <button type="button" class="bg-blue-dark-1 white bold no-border px-3 py-1 mx-2 mb-2" (click)="closeDialog()">Fermer</button>
            </div>
        </div>
    </div>
  </div>
  `
})

export class DimensionnementPerimetre
{
    date:number = new Date().getFullYear()
    idUser!:number

    constructor(
        private dialog: MatDialog,
        private sharedService: SharedService
    )
    {
        const id_user = this.sharedService.getIdUser()
        this.idUser = id_user
    }

    download()
    {
        const link = document.createElement("a")
        link.href = '/assets/excel/Tableau_communes.xlsx'
        link.download = `Tableau_communes_${this.date}.xlsx`
        link.click()
        window.URL.revokeObjectURL(link.toString())

        this.sharedService.postTableConnexion(this.idUser, 434, this.idUser).subscribe()
    }

    closeDialog()
    {
        this.dialog.closeAll()
    }
}

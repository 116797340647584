import { MatPaginatorIntl } from "@angular/material/paginator";
import { Injectable } from "@angular/core";

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl
{
    override nextPageLabel: string = "Page suivante"
    override previousPageLabel: string = "Page précédente"
    override firstPageLabel: string = "Première page"
    override lastPageLabel: string = "Dernière page"
}
import { Injectable, inject } from '@angular/core';
import { ConnexionService } from '../connexion/connexion.service';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

export @Injectable({
  providedIn: 'root'
})
class RouteGuardService
{
  constructor(
    private connexionService:ConnexionService,
    private router:Router
  ){}

  canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree 
  {  
    if( this.connexionService.isLoggedIn() && !this.connexionService.isRefreshTokenExpired() ) return true
    else return this.router.parseUrl('/connexion')  
  }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree  => 
{
  return inject(RouteGuardService).canActivate(next, state);
}


import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertSize',
  standalone: true
})
export class ConvertSizePipe implements PipeTransform 
{

  transform(size:number): string
  {
    let suffix:string = " octet(s)"

    if(size >= 1024) suffix = " Ko"
    if(size >= 1048576) suffix = " Mo"
    if(size >= 1073741824) suffix = " Go"
    if(size >= 1099511627776) suffix = " To"

    switch (suffix) 
    {
      case " Ko":
        size = Math.round(size / 1024)
      break
      case " Mo":
        size = Math.round(size / 1048576)
      break
      case " Go":
        size = Math.round(size / 1073741824)
      break
      case " To":
        size = Math.round(size / 1099511627776)
      break
    }

    return size.toString() + suffix
  }

}

import { Component, SimpleChanges } from '@angular/core';
import { Dossiers } from '../../../interfaces/Dossiers';
import { SharedService } from '../../../services/shared/shared.service';
import { SharedModule } from "../../../modules/shared/shared.module";
import { DashboardService } from '../../../services/dashboard/dashboard.service';
import { getElementsOnPage } from '../../../helpers/functions/GetElementsOnPage';

@Component({
  selector: 'app-saisines-prematurees',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './saisines-prematurees.component.html',
  styleUrl: './saisines-prematurees.component.css'
})
export class SaisinesPrematureesComponent 
{
  title:string = "Saisines prématurées" 
  pageSize:number = getElementsOnPage()
  pageIndex:number = 1
  idService!:number
  totalPages!:number
  totalResult!:number
  data_header_table = 
  {
      "badge":"",
      "ref":"N°dossier MDE",
      "nom_dossier":"Nom du dossier",
      "num_contrat":"N°de contrat",
      "date":"Date saisine",
      "etat":"État"
  }
  data_body_table: Dossiers[] = []
  isDataReady:boolean = false

  constructor
  (
    private sharedService:SharedService,
    private dashboardService: DashboardService
  ) 
  {
    const id_service = this.sharedService.getIdService()
    this.idService = id_service
    this.createPagination(this.pageSize)
  }

  ngOnInit()
  {
    this.getData()
  }

  ngOnChanges(changes: SimpleChanges) 
  {
    if ('search' in changes) 
    {
      this.getData()
    }
  }

  changePage(currentPage:number, pageSize:number=this.pageSize, changeDisplay:boolean=false)
  {  
    this.pageIndex = changeDisplay == false ? currentPage : 1 
    this.pageSize = pageSize
    this.getData()
  }

  async createPagination(pageSize:number)
  {
    (await
      this.sharedService.getInfoPagination("saisines_prematurees", this.idService)).subscribe(data => {
      this.totalResult = data[0]?.total_result
      this.totalPages = Math.ceil(this.totalResult / pageSize)
    })
  }

  getData(search:string="")
  {
    this.dashboardService.getSaisinesPrematurees(this.pageIndex, this.pageSize, this.idService, search).subscribe((data:Dossiers[]) => {
        this.data_body_table = data
        this.isDataReady = true
    })
  }
}

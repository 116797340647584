import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService 
{

  constructor
  (
    private router: Router
  ) { }

  navigateTo(url:string, params: { [key: string]: any})
  {
    const navigationExtras: NavigationExtras = {queryParams: params}
    this.router.navigate([url], navigationExtras)
  }

  navigateByURL(url:string, params: { [key: string]: any})
  {
    const navigationExtras: NavigationExtras = {queryParams: params}
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([url], navigationExtras)})
  }
}

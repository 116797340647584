import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Alerte } from '../../interfaces/Alerte';


@Injectable({
  providedIn: 'root'
})
export class AlertService 
{
  private readonly HOST:string  = environment.BASE_URL

  constructor(
    private httpClient: HttpClient
  ) { }

  getData():Observable<Alerte[]>
  {
    const url = `${this.HOST}/alertes`

    return this.httpClient.get<Alerte[]>(url)
  }
}

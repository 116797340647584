import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DataDashboard, DataDashboardMessage } from '../../interfaces/DataDashboard';
import { Dossiers } from '../../interfaces/Dossiers';
import { MessageIn } from '../../interfaces/MessageIn';

@Injectable({
  providedIn: 'root'
})
export class DashboardService 
{
  private readonly HOST:string  = environment.BASE_URL

  constructor(
    private httpClient: HttpClient
  ) { }


  countDossiersHorsDelai(id_service:number):Observable<{total_result:number}[]>
  {
    const url = `${this.HOST}/countDossiersHorsDelai`

    return this.httpClient.get<{total_result:number}[]>(url, 
      {
        params : 
        {
          id_service: id_service
        }
      })
  }

  countMessagesNonLus(id_service:number, id_user:number):Observable<{total_result:number}[]>
  {
    const url = `${this.HOST}/countMessagesNonLus`

    return this.httpClient.get<{total_result:number}[]>(url, 
      {
        params : 
        {
          id_service,
          id_user
        }
      })
  }

  countDossiersEnCours(id_service:number):Observable<{total_result:number}[]>
  {
    const url = `${this.HOST}/countDossiersEnCours`

    return this.httpClient.get<{total_result:number}[]>(url, 
      {
        params : 
        {
          id_service: id_service
        }
      })
  }

  countDossiersClos(id_service:number):Observable<{total_result:number}[]>
  {
    const url = `${this.HOST}/countDossiersClos`

    return this.httpClient.get<{total_result:number}[]>(url, 
      {
        params : 
        {
          id_service: id_service
        }
      })
  }

  countSaisinesPrematurees(id_service:number):Observable<{total_result:number}[]>
  {
    const url = `${this.HOST}/countSaisinesPrematurees`

    return this.httpClient.get<{total_result:number}[]>(url, 
      {
        params : 
        {
          id_service: id_service
        }
      })
  }


  getLastDossiers(id_utilisateur:number, id_service:number):Observable<DataDashboardMessage[]>
  {
    const url = `${this.HOST}/getLastDossiers`

    return this.httpClient.get<DataDashboardMessage[]>(url, 
      {
        params : 
        {
          id_utilisateur: id_utilisateur,
          id_service: id_service
        }
      })
  }

  getDossiersHorsDelai(pageIndex:number, pageSize:number, id:number, search:string=""):Observable<Dossiers[]>
  {
    const url = `${this.HOST}/tableau-de-bord/dossiers-hors-delai`

    return this.httpClient.get<Dossiers[]>(url,
      {
        params : 
        {
          pageIndex:pageIndex,
          pageSize:pageSize,
          id:id,
          search:search
        }
      })
  }

  getDossiersEnCours(pageIndex:number, pageSize:number, id:number, search:string=""):Observable<Dossiers[]>
  {
    const url = `${this.HOST}/tableau-de-bord/dossiers-en-cours`

    return this.httpClient.get<Dossiers[]>(url,
      {
        params : 
        {
          pageIndex:pageIndex,
          pageSize:pageSize,
          id:id,
          search:search
        }
      })
  }

  getDossiersClos(pageIndex:number, pageSize:number, id:number, search:string=""):Observable<Dossiers[]>
  {
    const url = `${this.HOST}/tableau-de-bord/dossiers-clos`

    return this.httpClient.get<Dossiers[]>(url,
      {
        params : 
        {
          pageIndex:pageIndex,
          pageSize:pageSize,
          id:id,
          search:search
        }
      })
  }

  getSaisinesPrematurees(pageIndex:number, pageSize:number, id:number, search:string=""):Observable<Dossiers[]>
  {
    const url = `${this.HOST}/tableau-de-bord/saisines-prematurees`

    return this.httpClient.get<Dossiers[]>(url,
      {
        params : 
        {
          pageIndex:pageIndex,
          pageSize:pageSize,
          id:id,
          search:search
        }
      })
  }

  getMessagesNonLus(pageIndex:number, pageSize:number, id:number, search:string=""):Observable<MessageIn[]>
  {
    const url = `${this.HOST}/tableau-de-bord/messages-non-lus`

    return this.httpClient.get<MessageIn[]>(url,
      {
        params : 
        {
          pageIndex:pageIndex,
          pageSize:pageSize,
          id:id,
          search:search
        }
      })
  }
}

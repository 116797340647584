import { isPlatformBrowser } from '@angular/common';
import { Inject, PLATFORM_ID, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService 
{
  constructor
  (
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  get(item:string)
  {
    if (isPlatformBrowser(this.platformId)) return sessionStorage.getItem(item)
    else return
  }

  set(name:string,value:string)
  {
    if (isPlatformBrowser(this.platformId)) return sessionStorage.setItem(name, value)
  }

  remove(item:string)
  {
    if (isPlatformBrowser(this.platformId)) return sessionStorage.removeItem(item)
  }
}

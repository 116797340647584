import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpCancelService 
{
  private cancelPendingRequests$ = new Subject<void>()

  getCancelPendingRequests() 
  {
    return this.cancelPendingRequests$
  }

  cancelPendingRequests() 
  {
    this.cancelPendingRequests$.next()
  }
}

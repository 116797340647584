import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Pagination } from '../../interfaces/Pagination';
import { ProfilService } from '../profil/profil.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Dossier_Data, Partenariat_Data } from '../../interfaces/Dossier_Partenariat_Data';
import { NavigationService } from '../navigation/navigation.service';
import { DossiersService } from '../dossiers/dossiers.service';
import { PartenariatService } from '../partenariat/partenariat.service';
import { replaceAccentsWithEntities } from '../../helpers/functions/ReplaceAccents';
import { MatSnackBar } from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})
export class SharedService 
{
  private readonly HOST:string  = environment.BASE_URL
  private readonly URL_SITE:string  = environment.URL_SITE
  isButtonBurgerVisible:boolean = true
  thresholdTablet :number = 1400
  thresholdMobile :number = 850
  current_service:number = this.getCurrentService()
  data = JSON.parse(sessionStorage.getItem(`service${this.current_service}`) as string) || null
  reloadSidebarData = new BehaviorSubject<boolean>(false)
  addBackdrop = new BehaviorSubject<boolean>(false)
  isSearchBarEmpty = new BehaviorSubject<boolean>(true)

  constructor
  (
    private httpClient:HttpClient,
    private profilService: ProfilService,
    private dialog: MatDialog,
    private router: Router,
    private navigationService: NavigationService,
    private dossierService: DossiersService,
    private partenariatService: PartenariatService,
    private snackBar: MatSnackBar
  ) {}

  reFetchData()
  {
    this.current_service = this.getCurrentService()
    this.data = JSON.parse(sessionStorage.getItem(`service${this.current_service}`) as string)
    this.reloadSidebarData.next(true)
  }

  getDataStorage()
  {
    this.data = JSON.parse(sessionStorage.getItem(`service${this.current_service}`) as string)
  }

  getDataPopup(id:number):Observable<any>
  {
    const url = `${this.HOST}/popup`

    return this.httpClient.get(url, 
      {
        params : 
        {
          id:id
        }
      })
  }

  async getInfoPagination(map_sql_string:string, id:number, id_user:number=0):Promise<Observable<Pagination[]>>
  {
    const url = `${this.HOST}/pagination`

    let params
    
    if(id_user !== 0)
    {
      params = 
      {
        map_sql_string,
        id,
        id_user
      }
    }
    else
    {
      params = 
      {
        map_sql_string,
        id
      }
    }

    if( map_sql_string )
    {
      return this.httpClient.get<Pagination[]>(url, 
        {
          params : params
        })
    }
    else
    {
      return of()
    }

  }

  getIdUser()
  {
    // return Number(sessionStorage.getItem("id_user"))
    return (sessionStorage.getItem("id_user") as any)
  }

  getIdService()
  {
    this.getDataStorage()
    return this.data.id_service
  }

  getService()
  {
    this.getDataStorage()
    return this.data.service_eau
  }

  getCurrentService()
  {
    return Number(localStorage.getItem("current_service")) || 0
  }

  isPartenaire()
  {
    this.getDataStorage()
    return this.data.is_partenaire
  }

  getLastItem()
  {
    this.getDataStorage()
    return this.data?.last_item
  }

  getLastItemType()
  {
    this.getDataStorage()
    return this.data?.last_item_type
  }

  getLastItemId()
  {
    this.getDataStorage()
    return this.data?.last_item_id
  }

  setLastItem(ref:string)
  {
    this.data.last_item = ref
    sessionStorage.setItem(`service${this.current_service}`, JSON.stringify(this.data))
  }

  setLastItemType(string:string)
  {
    this.data.last_item_type = string
    sessionStorage.setItem(`service${this.current_service}`, JSON.stringify(this.data))
  }

  setLastItemId(id:number)
  {
    this.data.last_item_id = id
    sessionStorage.setItem(`service${this.current_service}`, JSON.stringify(this.data))
  }

  getUserRights()
  {
    const gestion_dossier = this.data.gestion_dossier
    const copie_message = this.data.copie_message
    const gestion_convention_facturation = this.data.gestion_convention_facturation
    const saisie_donnees_abonnes = this.data.saisie_donnees_abonnes

    return {gestion_dossier, copie_message, gestion_convention_facturation, saisie_donnees_abonnes}
  }

  getParam(value:string) // avoir des paramètre empèche le rafraichissement de la page sur le serveur 
  {
    const url_fragments = this.router.url.split("/")
    const match = url_fragments.find(url => url.includes(value))
    if(!match || match == undefined) return

    return match.split("_").slice(-1)[0]
  }

  goToHomePage()
  {
    return this.navigationService.navigateTo("", [null])
  }

  goToContactPage()
  {
    const id_user = this.getIdUser()

    this.profilService.getProfil(id_user).subscribe(data => {
        const { civilite, telephone, mobile, fonction, gestion_dossier, copie_message, gestion_convention_facturation, saisie_donnees_abonnes, ...profil} = data[0]

        const link = document.createElement("a")

        const data_form_contact = replaceAccentsWithEntities(JSON.stringify(Object.values(profil)))

        link.href = `${this.URL_SITE}/FR/contact/se_sa-nous-contacter.asp?data=${btoa(data_form_contact)}`
        link.target = "_blank"
        link.click()
    })
  }

  openDialog(component:any)
  {
    const dialogRef = this.dialog.open(component, {disableClose: true})
    
    dialogRef.afterClosed().subscribe( result => {
      console.log("dialog closed")
    })
  }

  blurPage()
  {    
    this.addBackdrop.next(true)

    this.snackBar.open("Veuillez patienter ...", "Ok")

    // document.addEventListener("click", (e:any) => { 
    //   if( e.target && e.target.matches(".backdrop"))
    //   {
    //     this.focusPage() 
    //   }
    // })

    //* Quoi qu'il arrive, le flou d'arrière plan, reste au maximum 10 secondes
    setTimeout(() => {

      this.focusPage()
    }, 1000 * 10)
  }

  focusPage()
  {
    this.addBackdrop.next(false)
  }
  
  isDossierData(data: any): data is Dossier_Data 
  {
    return (data as Dossier_Data).id_dossier !== undefined
  }

  isPartenariatData(data: any): data is Partenariat_Data
  {
    return (data as Partenariat_Data).id_dip !== undefined
  }

  showSidebar()
  {
    (document.getElementById("sidebar") as HTMLElement).style.display = 'initial'
  }

  hideSidebar()
  {
    (document.getElementById("sidebar") as HTMLElement).style.display = 'none'
  }

  switchToTabletMode()
  {
    return window.innerWidth < this.thresholdTablet ? true : false
  }

  switchToMobileMode()
  {
    return window.innerWidth < this.thresholdMobile ? true : false
  }

  postTableConnexion(id_user:number, id_action:number, id_table:number):Observable<number>
  {
    const url = `${this.HOST}/liste-actions`

    return this.httpClient.post<number>(url, {id_user, id_action, id_table})
  }

  markDocumentAsRead(id_document:number, id_user:number)
  {
    this.dossierService.updateDocument([id_document]).subscribe(data => {
      if(data) 
      {
        this.postTableConnexion(id_user, 502, id_document).subscribe()
      }
    })
  }

  markDocumentPartenariatAsRead(id_document:number, id_user:number)
  {
    this.partenariatService.updateDocument([id_document]).subscribe(data => {
      if(data) 
      {
        this.postTableConnexion(id_user, 502, id_document).subscribe()
      }
    })
  }
}
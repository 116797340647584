<header class="header d-flex justify-content-between">
    <div class="brand d-flex p-1">
        <img src="/assets/img/logo-mediation-eau.png" alt="logo mediation eau">
        <h6 class="grey align-self-end">{{ nom_espace }} {{ getServiceEau() }}</h6>
    </div>
    <div class="user">
        <div class="toggleService">
            <div class="dropdend">
                <button 
                    type="button" 
                    class="no-border bg-blue white px-3 py-2 me-5 dropdown-toggle" 
                    data-bs-toggle="dropdown"
                    *ngIf="getCountService() > 1">
                    Changer de service
                </button>
                <ul class="dropdown-menu">
                    <li class="cursor my-1" *ngFor="let item of service_list">
                        <a class="dropdown-item text-start" (click)="changeService(item.num)">{{item.service_eau}}</a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="hello">
            <div class="small right bold grey-dark">
                @if( isDataReady )
                {
                    Bonjour, {{civilite}} {{ nom }} {{ prenom }}
                }
            </div>
            <div class="small right grey">Derniere connexion le {{ last_connexion }}</div>
        </div>
        <div class="profile relative">
            <div class="dropdend">
                <img class="img picto-x2 cursor me-5 dropdown-toggle" src="/assets/img/picto-profil.png" data-bs-toggle="dropdown" aria-expanded="false">
                  
                <ul class="dropdown-menu">
                    <li class="dropdown-item cursor" routerLink="/profil" routerLinkActive="router-link-active">Mon profil</li>
                    <li class="dropdown-item cursor toggleServiceHeader" (click)="$event.stopPropagation()">
                        <div class="dropdend">
                            <button 
                                type="button" 
                                class="no-border bg-transparent dropdown-toggle hover-text-white" 
                                data-bs-toggle="dropdown">
                                Changer de service
                            </button>
                            <ul class="dropdown-menu">
                                <li class="cursor my-1" (click)="changeService(i)" *ngFor="let item of service_list; let i = index">
                                    <a class="dropdown-item text-start">{{item.service_eau}}</a>
                                </li>
                            </ul>
                        </div>
                </li>
                    <li class="dropdown-item cursor" routerLink="/interlocuteurs" routerLinkActive="router-link-active">Interlocuteurs</li>
                    <li class="dropdown-item cursor" routerLink="/liste-actions" routerLinkActive="router-link-active">Liste des actions</li>
                    <li class="dropdown-item cursor test" ><img class="picto-deconnexion" src="/assets/icons/picto-deconnexion-black.png" alt="déconnexion"><a (click)="deconnexion()">Déconnexion</a></li>
                </ul>
            </div>
        </div>
    </div>
</header>


import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MessageOutSuiviPartenariat } from '../../interfaces/MessageOut';

@Injectable({
  providedIn: 'root'
})
export class SuiviPartenariatService 
{
  private readonly HOST:string  = environment.BASE_URL

  constructor
  (
    private httpClient:HttpClient
  ) { }

  postMessage(message:MessageOutSuiviPartenariat):Observable<{INSERTED_ID:number}[]>
  {
    const url = `${this.HOST}/partenariat/suivi-partenariat/messages`

    return this.httpClient.post<{INSERTED_ID:number}[]>(url, message)
  }

  updateMessage(id_message:number):Observable<number>
  {
    const url = `${this.HOST}/partenariat/suivi-partenariat/messages`

    return this.httpClient.put<number>(url,       
    {
      params : 
      {
        id_message:id_message
      }
    })
  }

  updateDocument(id_document:number[], id_message:number[]=[]):Observable<number>
  {
    const url = `${this.HOST}/partenariat/suivi-partenariat/documents`

    return this.httpClient.put<number>(url,       
    {
      params : 
      {
        id_document:id_document,
        id_message:id_message
      }
    })
  }
}

import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ConnexionService } from '../services/connexion/connexion.service';
import { CommonModule } from '@angular/common';
import { SharedService } from '../services/shared/shared.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';


@Component({
  standalone: true,
  imports: [CommonModule,ReactiveFormsModule],
  template: `
  <div>

    <form class="dialog d-flex border border-secondary" [formGroup]="form">
      <div class="col-4 bg-blue white bold dialog-title">REINITIALISATION DU MOT DE PASSE</div>
      <div class="col-8 bg-white blue-dark-1 dialog-content-group">
          <div class="dialog-content">
              <p>Veuillez renseigner votre adresse email</p>
  
              <div class="d-flex align-items-center">
                <span>Email:</span>
                <input type="email" class="ms-2 p-1 pe-5 form-control" formControlName="email" oninput="this.value = this.value.trimStart()" required>
              </div>

              <br>
              
              <span class="red" *ngIf="isSubmitted && isInvalid('email') && form.get('email')?.errors"><em><p>* Veuillez entrer une adresse email valide</p></em></span>
  
              <button type="button" class="bg-blue white no-border py-2 px-3 mt-2 float-end" (click)="submit()">Envoyer</button>

              <p class="result red" *ngIf="userNotFound">Aucun utilisateur n'est lié à cette adresse email</p>

              <br>
  
          </div>
      </div>
    </form>
  </div>
  `
})

export class NewPassword
{
    form!:FormGroup
    userNotFound:boolean = false
    isSubmitted:boolean = false


    constructor
    (
      private connexionService: ConnexionService,
      private sharedService: SharedService,
      private snackBar: MatSnackBar,
      private dialogRef: MatDialogRef<NewPassword>
    )
    {
      this.form = new FormGroup({
        email: new FormControl("",[Validators.required, Validators.email , Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,3}$')]),
      })
    }


    isInvalid(controlName: string)
    {
      const control = this.form.get(controlName)
      return control?.invalid && (control.dirty || control.touched)
    }

    async submit()
    {
      this.isSubmitted = true

      if( this.form.invalid || this.form.get('email')?.value == '' )
      {
        this.form.markAsDirty()
        this.form.markAllAsTouched()
        console.log("invalid")
        return
      }

      (await this.connexionService.forgotPassword(this.form.value.email)).subscribe({
          next: (data) => 
          {
            if( data == 0 || !data ) 
            {
              this.userNotFound = true
              return
            }

            this.dialogRef.close()
            
            const snackBarRef = this.snackBar.open("Vous allez recevoir un email qui vous permettra de renouveller votre mot de passe dans quelques instants", "Ok")

            // Utilisation du timeout car sur firefox, si on utlise la prorpiété 'duration' de 'this.snackBar.open', le changement de page est bloqué
            setTimeout(() => {
              snackBarRef.dismiss()
            }, 5000)
            
            this.sharedService.postTableConnexion(data.id_utilisateur, 460, data.id_utilisateur ).subscribe()
          },
          error: (e:Error) => 
          {
            console.log(e)
          }
      })
    }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Partenariat, PartenariatDetails } from '../../interfaces/Partenariat';
import { Suivi } from '../../interfaces/Suivi';
import { Document } from '../../interfaces/Document';
import { Message, MessageIn } from '../../interfaces/MessageIn';
import {  MessageOutPartenariat } from '../../interfaces/MessageOut';

@Injectable({
  providedIn: 'root'
})
export class PartenariatService 
{
  private readonly HOST:string  = environment.BASE_URL

  constructor
  (
    private httpClient:HttpClient
  ) { }

  getData(pageIndex:number, pageSize:number, id:number, search:string=""):Observable<Partenariat[]>
  {
    const url = `${this.HOST}/partenariat-liste`

    return this.httpClient.get<Partenariat[]>(url,
    {
      params : 
      {
        pageIndex:pageIndex,
        pageSize:pageSize,
        id:id,
        search:search
      }
    })
  }

  getDetails(id_service:string|number, id_utilisateur:string|number):Observable<PartenariatDetails[]>
  {
    const url = `${this.HOST}/partenariat-details`

    return this.httpClient.get<PartenariatDetails[]>(url, 
    {
      params : 
      {
        id_service:id_service,
        id_utilisateur:id_utilisateur
      }
    })
  }

  getSuivi(id_service:number, id_dip:number, ref_dip:string, id_utilisateur:number):Observable<Suivi[]>
  {
    const url = `${this.HOST}/partenariat-details/suivi`

    return this.httpClient.get<Suivi[]>(url, 
    {
      params : 
      {
        id_service:id_service,
        id_dip:id_dip,
        ref_dip:ref_dip,
        id_utilisateur:id_utilisateur
      }
    })
  }

  getAllDocuments(pageIndex:number, pageSize:number, id:number, search:string=""):Observable<Document[]>
  {
    const url = `${this.HOST}/partenariat/documents`

    return this.httpClient.get<Document[]>(url,
    {
      params : 
      {
        pageIndex:pageIndex,
        pageSize:pageSize,
        id:id,
        search:search
      }
    })
  }

  getAllMessages(pageIndex:number, pageSize:number, id:number, search:string=""):Observable<MessageIn[]>
  {
    const url = `${this.HOST}/partenariat/messages`

    return this.httpClient.get<MessageIn[]>(url,
    {
      params : 
      {
        pageIndex:pageIndex,
        pageSize:pageSize,
        id:id,
        search:search
      }
    })
  }

  getDocuments(id:number):Observable<Document[]>
  {
    const url = `${this.HOST}/partenariat-details/documents`

    return this.httpClient.get<Document[]>(url, 
    {
      params : 
      {
        id:id
      }
    })
  }

  getMessages(id_dip:number, id_service:number, id_user:number):Observable<Message[]>
  {
    const url = `${this.HOST}/partenariat-details/messages`

    return this.httpClient.get<Message[]>(url, 
    {
      params : 
      {
        id_dip,
        id_service,
        id_user
      }
    })
  }

  postMessage(message:MessageOutPartenariat):Observable<{INSERTED_ID:number}[]>
  {
    const url = `${this.HOST}/partenariat-details/messages`

    return this.httpClient.post<{INSERTED_ID:number}[]>(url, message)
  }

  updateMessage(id_message:number):Observable<number>
  {
    const url = `${this.HOST}/partenariat-details/messages`

    return this.httpClient.put<number>(url,       
    {
      params : 
      {
        id_message:id_message
      }
    })
  }

  updateDocument(id_document:number[], id_message:number[]=[]):Observable<number>
  {
    const url = `${this.HOST}/partenariat-details/documents`

    return this.httpClient.put<number>(url,       
    {
      params : 
      {
        id_document:id_document,
        id_message:id_message
      }
    })
  }
}

<div class="d-flex me-4" *ngIf="totalResult > selectValue">
    <label>Affichage</label>
    &nbsp;

    <select class="select" (change)="changeItemsPerPage($event)" [value]="selectValue">
        @for (item of itemsOnPages; track $index) 
        {
            <option value="{{$index}}" [selected]="$index == selectValue ? 'selected' : ''">{{item}} lignes / page </option>
        }
    </select>

</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LastConnexion, ListeActions } from '../../interfaces/ListeActions';

@Injectable({
  providedIn: 'root'
})
export class ListeActionsService 
{
  private readonly HOST:string  = environment.BASE_URL
  constructor
  (
    private httpClient:HttpClient
  ) { }

  getData(pageIndex:number, pageSize:number, id:number, search:string=""):Observable<ListeActions[]>
  {
    const url = `${this.HOST}/liste-actions`

    return this.httpClient.get<ListeActions[]>(url,
      {
        params : 
        {
          pageIndex:pageIndex,
          pageSize:pageSize,
          id:id,
          search:search
        }
      })
  }
  
  getLastConnexion(id:number):Observable<LastConnexion[]>
  {
    const url = `${this.HOST}/liste-actions/last-connexion`

    return this.httpClient.get<LastConnexion[]>(url,
      {
        params : 
        {
          id:id
        }
      })
  }

  postTableConnexion(id_user:number, id_action:number, id_table:number):Observable<number>
  {
    const url = `${this.HOST}/liste-actions`

    return this.httpClient.post<number>(url, {id_user, id_action, id_table})
  }
}

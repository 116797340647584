export function splitLastSeparator(str:string, separator:string) 
{
    if( !str || !separator ) 
    {
        return ['', '']
    }

    if( typeof str != "string" || typeof separator != "string" ) 
    {
        return [str, '']
    }

    const lastSeparatorIndex = str.lastIndexOf(separator)
    
    // Si le separateur n'est pas présent, retourne la chaine de caractères et une chaine de caractères vide
    if (lastSeparatorIndex === -1) 
    {
        return [str, '']
    }
    
    const beforeLastSeparator = str.slice(0, lastSeparatorIndex)
    const afterLastSeparator = str.slice(lastSeparatorIndex + 1)
    
    return [beforeLastSeparator, afterLastSeparator]
}
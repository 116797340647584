import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DossiersService } from '../services/dossiers/dossiers.service';
import { PartenariatService } from '../services/partenariat/partenariat.service';
import { Message } from '../interfaces/MessageIn';
import { DownloadService } from '../services/download/download.service';
import { Dossier_Data, Partenariat_Data } from '../interfaces/Dossier_Partenariat_Data';
import { SharedService } from '../services/shared/shared.service';
import { SharedModule } from '../modules/shared/shared.module';


@Component({
  selector: 'app-message',
  standalone: true,
  imports: [CommonModule, SharedModule],
  template: `
      <div class="d-flex border-dark px-3 py-5 message-container" *ngIf="messageList.length > 0">
          <div class="col-4 list-group">
            @if (messageList.length > 0) 
            {
              @for (item of messageList; track $index) 
              {
                <ul class="list-group-item cursor py-3 ps-2 pe-5 message d-flex message_{{item.id_message}}" 
                  (click)="
                  setActive($event, item.id_message);
                  displayMessage($index);
                  item.non_lu ? markAsRead(item.id_message) : ''
                ">
                  <span class="badge badge-pill align-self-start me-2" [ngClass]="item.non_lu ? 'opacity-100': 'opacity-0'">N</span> 
                  <div>
                    <li class="bold titre">{{ item.titre }}</li>
                    <li class="texte text-break">
                      {{ (item.date_message | date:'dd-MM-yyyy') ?? '' }} - {{ item.expediteur }} 
                      @if( item.documents.length > 0 )
                      {
                        - {{ item.documents.length }} pièces jointes
                      }
                    </li>
                    <li class="texte">État: {{ item.etat }}</li>
                  </div>
                  <img class="white-filter rotate-right justify-content-end align-self-center" src="/assets/icons/triangle.png" height="20" style="position:absolute;right:0.9rem">
                </ul>
              }
            }
          </div>
          <div class="col-8 px-5 content-container" id="print-message-content">
            <div class="none print">
              <div class="mb-1" *ngIf="expediteur">De : {{expediteur}}</div>
              <div class="mb-1" *ngIf="destinataire">A : {{destinataire}}</div>
              <div class="mb-1" *ngIf="titre">Objet : {{titre}}</div>
              <div class="mb-1" *ngIf="liste_pieces_jointes">Pièce(s) jointe(s) : 
                @for (item of liste_pieces_jointes; track item) 
                {
                  <div>{{item}}</div>
                }
              </div>
              <br>
            </div>

            <section class="message-content d-none" [ngClass]="documentList && documentList.documents.length > 0 ? 'halfPage' : 'fullPage' ">
              <h5 class="bold dark">
                {{ titre }} 
                <button class="bg-orange white no-border p-2 me-3 h6 float-end no-print no-print-button" (click)="print('print-message-content')">Imprimer</button>
              </h5>
              <small>{{ (date_message | date:'dd-MM-yyyy') ?? '' }} {{ " - " + expediteur }} {{ pieces_jointes }}</small>
              <br>
              <small>{{ etat }}</small>
              <p class="mt-4" [innerHTML]="contenu"></p>
            </section>
            
            @if(isActive && pieces_jointes && documentList)
            {
              <hr>
              <section class="pj-container" [ngClass]="documentList && documentList.documents.length > 0 ? 'halfPage' : 'fullPage' ">
                <div class="d-flex justify-content-between" *ngIf="documentList && documentList.documents.length > 1">
                    <small>{{ pieces_jointes }}</small>
                    <small class="cursor me-3" (click)="downloadAll(0, documentList.documents)">Télécharger tout <img src="/assets/icons/picto-telecharger-tout.png" height="20" alt="télécharger tout"></small>
                </div>
    
                  <div>
                    <div class="row">
                      @for (document of documentList.documents; track $index) 
                      {
                        <div class="card col-5 border-0 bg-grey-light rounded-0 m-1" *ngIf="document">
                          <div class="card-body p-2 d-flex justify-content-between align-items-center">
                            <div class="text-break">
                              <img class="cursor" src="/assets/icons/picto-document.png" height="30" alt="voir la pièce jointe" *ngIf="document.is_downloadable" (click)="download(document.url_doc, idUser)">
                              <small class="">{{ document.fichier }}</small>
                            </div>
                            <img class="cursor" src="/assets/icons/picto-telecharger.png" height="20" alt="télécharger la pièce jointe" *ngIf="document.is_downloadable" (click)="download(document.url_doc, idUser)">
                          </div>
                        </div>
                      }
                    </div>
                  </div>
              </section>
            }
      </div>
  `,
  styles:
  `
    .list-group
    {
      border-radius: 0;
    }

    .list-group, .message-content, .pj-container:is(.halfPage), .document-table-wrapper
    {
      overflow-y: auto;
      scrollbar-width: thin;
      scrollbar-color: #292929;
      border-color: #292929;
    }

    .list-group-item
    {
      color: #828282;
    }

    .list-group-item > .titre,
    .list-group-item > .texte
    {
      font-size: 12px;
    }

    .list-group-item > .titre
    {
      font-weight: bold;
    }

    .list-group-item > .texte
    {
      font-weight: 500;
    }

    .pj-container:is(.halfPage)
    {
      height: 35%;
      overflow-x:hidden;
    }

    .message-content:is(.halfPage)
    {
      height: 65%;
    }

    .message-content:is(.fullPage)
    {
      height: 100%;
    }

    .message .titre,
    .message .texte
    {
      font-size: 12px;
    }

    .message > span
    {
      background-color: #2794D8;
      color: #fff;
    }

    .message-active > span
    {
      background-color: #fff;
      color: #2794D8;
    }

    .message-active
    {
      background-color: #2794D8;
      color: white;
    }

    .message-content
    {
      color: #292929;
      overflow-y: auto;
    }

    .message-container
    {
      height: 570px;
    }

    .document-table-wrapper
    {
      max-height: 500px;
    }

    @media print 
    {
      .print
      {
        display:block!important;
      }

      .no-print
      {
        display:none;
      }
    }
  `
})

export class MessageComponent 
{
    date:Date = new Date()
    formatedDate = this.date.toLocaleDateString().split("/").join('-')
    @Input() data!:Dossier_Data|Partenariat_Data
    @Input() anchor!:string
    isActive:boolean = false
    messageList: Message[] = []
    documentList: Message | null = null
    titre!:string
    date_message!: string
    expediteur!:string
    destinataire!:string
    etat!:string
    contenu!:string
    liste_pieces_jointes!:string[]
    pieces_jointes!:string
    idUser:number
    idService:number
    ref!:string
    isDataReady:boolean = false

    constructor
    (
      private dossierService: DossiersService,
      private partenariatService: PartenariatService,
      private downloadService: DownloadService,
      private sharedService: SharedService
    )
    {
      this.idUser = this.sharedService.getIdUser()
      this.idService = this.sharedService.getIdService()
    }

    ngOnInit()
    {
      window.addEventListener("afterprint", (event) => {
        window.close()
      })

      this.ref = this.sharedService.getParam('ref')?.toString() || ""
      
      this.getMessages()

      setInterval(() => {
        this.getMessages()
      }, 1000 * (2 * 60))
    }

    print(elements:string)
    {
      const htmlElement = document.getElementById(elements)
  
      if (!htmlElement) return

      const no_print_button = document.querySelector(".no-print-button")
      
      if(no_print_button) (no_print_button as HTMLElement).style.display = "none"
    
      // Create a hidden iframe for printing
      const printFrame = document.createElement("iframe")
      printFrame.style.position = "absolute"
      printFrame.style.width = "0"
      printFrame.style.height = "0"
      printFrame.style.border = "none"
      
      document.body.appendChild(printFrame)
    
      // Get the content to print
      const printContents = htmlElement.innerHTML
    
      // Write to the iframe and print
      const doc = printFrame.contentWindow?.document
      if (doc) 
      {
        doc.open()
        doc.write(`
          <html>
          <head><title>Print</title></head>
          <body>${printContents}</body>
          </html>
        `)
        doc.close()
    
        printFrame.contentWindow?.focus()
        printFrame.contentWindow?.print()
      }

      if(no_print_button) (no_print_button as HTMLElement).style.display = "initial"
    }

    getMessages()
    {
      if(this.data.type == "dossiers" && this.sharedService.isDossierData(this.data))
      {
        this.dossierService.getMessages(this.ref, this.data.id_dossier, this.data.id_service, this.idUser).subscribe(data => {
          if(data)
          {
            this.messageList = data.sort((a:any,b:any) => b.non_lu - a.non_lu )
          }
        })
      }
      else if(this.data.type == "partenariat" && this.sharedService.isPartenariatData(this.data))
      {
        this.partenariatService.getMessages(this.data.id_dip, this.data.id_service, this.idUser).subscribe(data => {
          if(data)
          {
            this.messageList = data.sort((a:any,b:any) => b.non_lu - a.non_lu )
          }
        })
      }
      this.isDataReady = true
    }

    setActive(event:Event, item_id:number)
    {
      this.isActive = true
      document.querySelector(".message-content")?.classList.remove("d-none")

      Array.from(document.querySelectorAll(".message"))
        .map( e => e.classList.remove("message-active"))

      document.querySelector(`.message_${item_id}`)?.children[0]
        .classList.replace("opacity-100","opacity-0")

      const message: EventTarget | null = event.currentTarget

      if(message) (message as HTMLElement).classList.add("message-active")
    }

    displayMessage(index:number)
    {
      this.pieces_jointes = ""
      this.documentList = null
      const message = this.messageList[index]
      this.titre = message.titre
      this.date_message = message.date_message.toString()
      this.expediteur = message.expediteur
      this.destinataire = message.destinataire
      this.etat = message.etat
      this.contenu = message.message

      if(message.documents.length > 0) 
      {
        const nb_pieces_jointes = message.documents.length
        const suffixe = nb_pieces_jointes > 1 ? 's' : ''
        this.pieces_jointes = ` - ${nb_pieces_jointes} pièce${suffixe} jointe${suffixe}`

        if( nb_pieces_jointes > 0 )
        {
          this.documentList = this.messageList.filter((obj: any) => message.documents == obj.documents)[0]
        }
        else
        {
          this.documentList = message
        }
      }

      this.liste_pieces_jointes = this.documentList?.documents.map(document => document.fichier) || []
    }

    markAsRead(id_message:number)
    {
      if(this.data.type == "dossiers" && this.sharedService.isDossierData(this.data))
      {
        this.dossierService.updateMessage(id_message).subscribe(data => {
          if(data) 
          {
            this.getMessages()
          }
        })
      }
      else if(this.data.type == "partenariat" && this.sharedService.isPartenariatData(this.data))
      {
        this.partenariatService.updateMessage(id_message).subscribe(data => {
          if(data) 
          {
            this.getMessages()
          }
        })
      }

      this.sharedService.postTableConnexion(this.idUser, 501, id_message).subscribe()
    }

    download(fileName:string, idUser:number=0)
    {
      fileName = fileName.split("/").slice(-1)[0].replaceAll(" ", "-")

      if( this.data.type == "partenariat" )
      {
        this.downloadService.downloadProcessPartenariat(this.idService, fileName, 'services', idUser, idUser)
      }
      else
      {
        this.downloadService.downloadProcess(this.ref, fileName, 'dossiers', idUser, idUser)
      }
    }

    downloadAll(id:number=0, documents:{id_document:number, is_downloadable:boolean, titre_doc:string, url_doc:string, fichier:string}[])
    {
      const list_pj = documents.map(document => { return document?.url_doc?.split("/").slice(-1)[0]})

      this.sharedService.blurPage()
      const path = this.data.type == 'dossiers' ? `dossiers/${this.data.ref}`: `services/${this.data.id_service}`
      const ref = this.data.type == 'dossiers' ? this.data.ref : this.data.id_service
      this.downloadService.downloadAllAttachments(ref.toString(), path, btoa(list_pj.toString())).subscribe(blob => {
        if(blob)
        {
          this.sharedService.focusPage()

          downloadBlob(this.data.ref, blob)
          this.sharedService.postTableConnexion(this.idUser, 434, id).subscribe()
        } 
      })
    }
}

function downloadBlob(ref:string, blob:Blob)
{
  const blobUrl = URL.createObjectURL(blob)
  const link = document.createElement("a")

  link.href = blobUrl
  link.download = ref.replaceAll(" ","_")
  link.click()
  window.URL.revokeObjectURL(link.toString())
}
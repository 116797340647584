import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Facturation } from '../../interfaces/Facturation';

@Injectable({
  providedIn: 'root'
})
export class FacturationService 
{
  private readonly HOST:string  = environment.BASE_URL

  constructor(
    private httpClient:HttpClient
  ) { }

  getData(pageIndex:number, pageSize:number, id:number, search:string=""):Observable<Facturation[]>
  {
    const url = `${this.HOST}/facturation`

    return this.httpClient.get<Facturation[]>(url,
      {
        params : 
        {
          pageIndex:pageIndex,
          pageSize:pageSize,
          id:id,
          search:search
        }
      })
  }
}

import { CommonModule } from '@angular/common';
import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-return-top-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './return-top-button.component.html',
  styleUrl: './return-top-button.component.css'
})
export class ReturnTopButtonComponent 
{
  showButtonThreshold:number = 200
  showButton:boolean = false

  returnToTop()
  {
    window.scrollTo(0,0)
  }

  @HostListener('window:scroll', [])
  onWindowScroll() 
  {
    this.showButton = window.scrollY > this.showButtonThreshold
  }
}

import { Component, ViewChild } from '@angular/core';
import { InterlocuteursService } from '../../services/interlocuteurs/interlocuteurs.service';
import { Interlocuteur } from '../../interfaces/Interlocuteur';
import { SharedModule } from '../../modules/shared/shared.module';
import { MatTableDataSource } from '@angular/material/table';
import { SharedService } from '../../services/shared/shared.service';
import { FormatPhoneNumberPipe } from "../../helpers/pipes/format-phone-number/format-phone-number.pipe";
import { BreadcrumbComponent } from "../../common/breadcrumb/breadcrumb.component";
import { MatSort } from '@angular/material/sort';


@Component({
    selector: 'app-interlocuteurs',
    standalone: true,
    templateUrl: './interlocuteurs.component.html',
    styleUrl: './interlocuteurs.component.css',
    imports: [SharedModule, FormatPhoneNumberPipe, BreadcrumbComponent]
})

export class InterlocuteursComponent
{
  title:string = "Interlocuteurs" 
  idService!:number
  idUser!:number
  displayedColumns: string[] = ['interlocuteur', 'fonction', 'email', 'telephone', 'mobile', 'gestion_dossiers','copie_messages','gestion_convention_facturation','saisie_donnees', 'droits']
  ELEMENT_DATA!: Interlocuteur[]
  dataSource!:MatTableDataSource<Interlocuteur>
  @ViewChild(MatSort) sort!: MatSort

  constructor
  (
    private interlocuteursService : InterlocuteursService,
    private sharedService: SharedService
  )
  {
    this.idService = this.sharedService.getIdService()
    this.idUser = this.sharedService.getIdUser()
  }

  ngOnInit()
  {
    this.getDataInterlocuteurs()
  }

  getDataInterlocuteurs(search:string="")
  {
    this.interlocuteursService.getData(this.idService, search).subscribe((data:Interlocuteur[]) => {
      this.ELEMENT_DATA = data
      this.dataSource = new MatTableDataSource<Interlocuteur>(this.ELEMENT_DATA)
      this.dataSource.sort = this.sort
    })
  }

  goToContactPage()
  {
    this.sharedService.goToContactPage()
  }

  switchToMobileMode()
  {
    return this.sharedService.switchToMobileMode()
  }
}


import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { Interlocuteur } from '../../interfaces/Interlocuteur';

@Injectable({
  providedIn: 'root'
})
export class InterlocuteursService 
{
  private readonly HOST:string  = environment.BASE_URL

  constructor(
    private httpClient:HttpClient
  ) { }

  getData(id:number, search:string=""):Observable<Interlocuteur[]>
  {
    const url = `${this.HOST}/interlocuteurs`

    return this.httpClient.get<Interlocuteur[]>(url, 
      {
        params : 
        {
          id:id,
          search:search
        }
      })
  }
}

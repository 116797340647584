import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatNumber',
  standalone: true
})
export class FormatNumberPipe implements PipeTransform 
{

  transform(value: number): string 
  {
    return value?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
  }

}

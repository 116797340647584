<app-backdrop></app-backdrop>

<div class="container">
    <div class="row">
        <div class="sidebar-burger" [ngClass]="switchToTabletMode() ? 'col-sm-1 px-0': ''">
            <app-menu-burger></app-menu-burger>
        </div>

        <div id="sidebar" class="col-sm-2 sidebar bg-blue">
            <app-sidebar></app-sidebar>
        </div>
    
        <div class="content-wrap" [ngClass]="switchToTabletMode() ? 'col-sm-11': 'col-sm-10'">
            <app-header></app-header>
            <app-search-dossiers></app-search-dossiers>
            <router-outlet></router-outlet>
        </div>
    </div>
</div>

<app-return-top-button></app-return-top-button>

<div class="footer">
    <app-footer></app-footer>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ExportExcel } from '../../interfaces/Export';

@Injectable({
  providedIn: 'root'
})
export class ExportService 
{
  private readonly HOST:string  = environment.BASE_URL

  constructor
  (
    private httpClient:HttpClient
  ) { }

  export(ref:string, id_service:number): Observable<ExportExcel[]>
  {
    const url = `${this.HOST}/export`

    return this.httpClient.get<ExportExcel[]>(url, 
      {
        params:
        {
          ref: ref,
          id_service: id_service
        }
      }
    )
  }
}

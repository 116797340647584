export function replaceAccentsWithEntities(str:string) 
{
    const accentMap:any = 
    {
        'á': '&aacute;', 'é': '&eacute;', 'í': '&iacute;', 'ó': '&oacute;', 'ú': '&uacute;', 'ñ': '&ntilde;',
        'Á': '&Aacute;', 'É': '&Eacute;', 'Í': '&Iacute;', 'Ó': '&Oacute;', 'Ú': '&Uacute;', 'Ñ': '&Ntilde;',
        'à': '&agrave;', 'è': '&egrave;', 'ì': '&igrave;', 'ò': '&ograve;', 'ù': '&ugrave;', 
        'À': '&Agrave;', 'È': '&Egrave;', 'Ì': '&Igrave;', 'Ò': '&Ograve;', 'Ù': '&Ugrave;',
        'â': '&acirc;', 'ê': '&ecirc;', 'î': '&icirc;', 'ô': '&ocirc;', 'û': '&ucirc;',
        'Â': '&Acirc;', 'Ê': '&Ecirc;', 'Î': '&Icirc;', 'Ô': '&Ocirc;', 'Û': '&Ucirc;',
        'ä': '&auml;', 'ë': '&euml;', 'ï': '&iuml;', 'ö': '&ouml;', 'ü': '&uuml;', 'ÿ': '&yuml;',
        'Ä': '&Auml;', 'Ë': '&Euml;', 'Ï': '&Iuml;', 'Ö': '&Ouml;', 'Ü': '&Uuml;',
        'ç': '&ccedil;', 'Ç': '&Ccedil;', 'œ': '&oelig;', 'Œ': '&OElig;', 'æ': '&aelig;', 'Æ': '&AElig;',
        'ß': '&szlig;', 'ø': '&oslash;', 'Ø': '&Oslash;', 'å': '&aring;', 'Å': '&Aring;',
    }

    return str.replace(/[^\u0000-\u007E]/g, function(match) {
        return accentMap[match] || match
    })
}

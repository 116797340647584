import { Component } from '@angular/core';
import { AlertComponent } from '../alert/alert.component';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { SidebarService } from '../../services/sidebar/sidebar.service';
import { CommonModule } from '@angular/common';
import { filter } from 'rxjs';
import { SharedService } from '../../services/shared/shared.service';
import { NavigationService } from '../../services/navigation/navigation.service';
import { environment } from '../../../environments/environment';


@Component({
    selector: 'app-sidebar',
    standalone: true,
    templateUrl: 'sidebar.component.html',
    styleUrl: 'sidebar.component.css',
    imports: [AlertComponent, RouterLink, CommonModule]
})
export class SidebarComponent
{
    nb_clos!:number | undefined
    nb_en_cours!:number | undefined
    nb_hors_delai!:number | undefined
    nb_message_non_lus!:number | undefined
    nb_prematures!:number | undefined
    nb_document!:number | undefined
    nb_message!:number | undefined
    nb_document_dip!:number | undefined
    nb_message_dip!:number | undefined
    nb_need_edit!:number | undefined
    isDetailsDossierVisible:boolean = false
    isDetailsPartenariatVisible:boolean = false
    ref!:string|null
    type_ref!:string|null
    idService!:number
    idUser!:number
    gestion_dossier!:boolean
    copie_message!:boolean
    gestion_convention_facturation!:boolean
    saisie_donnees_abonnes!:boolean
    lien_guide:string = environment.LINK_GUIDE


    constructor
    (
        private sidebarService: SidebarService,
        private sharedService: SharedService,
        private router: Router,
        private navigationService: NavigationService
    ) 
    {
        this.sharedService.reloadSidebarData.subscribe(data => {
            if( data == true )
            {
                this.clearDataSideBar()
                this.getUserRights()
                this.getData()
            }
        })
        this.getUserRights()
        this.removeRef()
    }
    
    ngOnInit()
    {
        this.getData()

        setInterval(() => {
            this.getData()
        }, 1000 * 60)
    }

    ngAfterViewInit()
    {
        this.highlightSidebar()

        this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(data => {
            this.setRef()
            this.setTypeRef()
            this.setIsDetailsDossierVisible()
            this.setIsDetailsPartenariatVisible()
            this.removeRef()
            this.highlightSidebar()

            if( window.innerWidth < 1400 ) this.sharedService.hideSidebar()
        })
    }

    highlightSidebar()
    {
        const route_split = this.router.url.split("/")[1]
        const url_end = route_split.startsWith("tableau-de-bord") || route_split.length == 0
            ? "dashboard"
            : this.router.url.split("/")[1] 

        this.removeHighlight()

        document.querySelector(`.${url_end}`)?.classList.add("active","collapsed")
    }

    removeHighlight()
    {
        Array.from(document.querySelectorAll(".nav-menu-item-group"))
            .map( e => e.classList.remove("active"))
    }

    setActive(event:Event)
    {
        this.removeHighlight()

        {(event.currentTarget as HTMLElement)?.classList.add("active")}
    }

    setRef()
    {
        this.ref = sessionStorage.getItem("ref") || null
    }

    setTypeRef()
    {
        this.type_ref = sessionStorage.getItem("type_ref") || null
    }

    setIsDetailsDossierVisible()
    {
        this.isDetailsDossierVisible = this.ref && this.type_ref == 'dossiers' ? true : false
    }

    setIsDetailsPartenariatVisible()
    {
        this.isDetailsPartenariatVisible = this.ref && this.type_ref == 'partenariat' ? true : false
    }

    removeRef()
    {
        sessionStorage.removeItem("ref")
    }

    clearDataSideBar()
    {
        this.nb_clos = undefined
        this.nb_en_cours = undefined
        this.nb_hors_delai = undefined
        this.nb_message_non_lus = undefined
        this.nb_prematures = undefined
        this.nb_document = undefined
        this.nb_message = undefined
        this.nb_document_dip = undefined
        this.nb_message_dip = undefined
        this.nb_need_edit = undefined
    }

    getUserRights()
    {
        const { gestion_dossier, copie_message, gestion_convention_facturation, saisie_donnees_abonnes } = this.sharedService.getUserRights()
        this.gestion_dossier = gestion_dossier
        this.copie_message = copie_message
        this.gestion_convention_facturation = gestion_convention_facturation
        this.saisie_donnees_abonnes = saisie_donnees_abonnes
        this.idService = this.sharedService.getIdService()
        this.idUser = this.sharedService.getIdUser()
    }

    getData()
    {
        this.sidebarService.getData(this.idService).subscribe(data => {
            if(data)
            {
                this.nb_clos = data[0]?.NB_CLOS
                this.nb_en_cours = data[0]?.NB_EN_COURS
                this.nb_hors_delai = data[0]?.NB_HORS_DELAI
                this.nb_message_non_lus = data[0]?.NB_MESSAGE_NON_LUS
                this.nb_prematures = data[0]?.NB_PREMATURES
                this.nb_document = data[0]?.NB_DOC
                this.nb_message = data[0]?.NB_MSG
                this.nb_document_dip = data[0]?.NB_DOC_DIP
                this.nb_message_dip = data[0]?.NB_MSG_DIP
                this.nb_need_edit = data[0]?.NB_EDIT
            }
        })

        this.sharedService.reloadSidebarData.next(false)
    }

    goTo(urlEnd:string)
    {
        this.navigationService.navigateByURL(`tableau-de-bord/${urlEnd}`, [null])
    }

    goToContactPage()
    {
        this.sharedService.goToContactPage()
    }

    displaySidebar()
    {
        this.sharedService.hideSidebar()
    }

    switchToTabletMode()
    {
        return this.sharedService.switchToTabletMode()
    }
}
import { Component } from '@angular/core';
import { SharedService } from '../../services/shared/shared.service';

@Component({
  selector: 'app-menu-burger',
  standalone: true,
  imports: [],
  templateUrl: './menu-burger.component.html',
  styleUrl: './menu-burger.component.css'
})
export class MenuBurgerComponent 
{
  constructor
  (
    private sharedService: SharedService
  ){}

  displaySidebar()
  {
    this.sharedService.showSidebar()
  }
}

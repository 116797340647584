import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { InfoSidebar } from '../../interfaces/InfoSidebar';

@Injectable({
  providedIn: 'root'
})
export class SidebarService 
{
  private readonly HOST:string  = environment.BASE_URL

  constructor(
    private httpClient: HttpClient
  ) { }

  getData(id:number):Observable<InfoSidebar[]>
  {
    const url = `${this.HOST}/sidebar`

    return this.httpClient.get<InfoSidebar[]>(url, 
      {
        params : 
        {
          id:id
        }
      })
  }
}

import { Component } from '@angular/core';
import { DashboardService } from '../../services/dashboard/dashboard.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NavigationService } from '../../services/navigation/navigation.service';
import { DataDashboardMessage } from '../../interfaces/DataDashboard';
import { SharedModule } from '../../modules/shared/shared.module';
import { SharedService } from '../../services/shared/shared.service';
import { LandingPage } from '../../helpers/functions/GoToLandingPage';

@Component({
    selector: 'app-home',
    standalone: true,
    templateUrl: 'home.component.html',
    styleUrl: 'home.component.css',
    imports: [MatProgressSpinnerModule, SharedModule]
})

export class HomeComponent
{
    nb_clos!:number | undefined
    nb_en_cours!:number | undefined
    nb_hors_delai!:number | undefined
    nb_message_non_lus!:number | undefined
    nb_prematures!:number | undefined
    derniers_messages: DataDashboardMessage[] = []
    idService:number
    idUser:number
    gestion_dossier:boolean
    copie_message:boolean
    gestion_convention_facturation:boolean
    saisie_donnees_abonnes:boolean

    constructor
    (
        private dashboardService: DashboardService,
        private navigationService: NavigationService,
        private sharedService: SharedService
    )
    {
        const { gestion_dossier, copie_message, gestion_convention_facturation, saisie_donnees_abonnes } = this.sharedService.getUserRights()
        this.gestion_dossier = gestion_dossier
        this.copie_message = copie_message
        this.gestion_convention_facturation = gestion_convention_facturation
        this.saisie_donnees_abonnes = saisie_donnees_abonnes
        this.idService = this.sharedService.getIdService()
        this.idUser = this.sharedService.getIdUser()

        this.sharedService.reloadSidebarData.subscribe(data => {
            if( data == true )
            {
                this.clearDataTiles()
                this.getData()
                this.getUserRights()
            }
        })
    }

    ngOnInit()
    {
        this.getData()
    }

    clearDataTiles()
    {
        this.nb_clos = undefined
        this.nb_en_cours = undefined
        this.nb_hors_delai = undefined
        this.nb_message_non_lus = undefined
        this.nb_prematures = undefined
        this.derniers_messages = []
    }

    getUserRights()
    {
        const { gestion_dossier, copie_message, gestion_convention_facturation, saisie_donnees_abonnes } = this.sharedService.getUserRights()
        this.gestion_dossier = gestion_dossier
        this.copie_message = copie_message
        this.gestion_convention_facturation = gestion_convention_facturation
        this.saisie_donnees_abonnes = saisie_donnees_abonnes
        this.idService = this.sharedService.getIdService()
        this.idUser = this.sharedService.getIdUser()

        new LandingPage().goToLandingPage({ gestion_dossier, copie_message, gestion_convention_facturation, saisie_donnees_abonnes }, this.navigationService)
    }

    getData()
    {
        this.countDossiersHorsDelai()
        this.countMessagesNonLus()
        this.countDossiersEnCours()
        this.countDossiersClos()
        this.countSaisinesPrematurees()
        this.getLastDossiers()
        this.sharedService.reloadSidebarData.next(false)
    }

    countDossiersHorsDelai()
    {
        this.dashboardService.countDossiersHorsDelai(this.idService).subscribe(data => {
            this.nb_hors_delai = data[0].total_result
        })
    }

    countMessagesNonLus()
    {
        this.dashboardService.countMessagesNonLus(this.idService, this.idUser).subscribe(data => {
            this.nb_message_non_lus = data[0].total_result
        })
    }

    countDossiersEnCours()
    {
        this.dashboardService.countDossiersEnCours(this.idService).subscribe(data => {
            this.nb_en_cours = data[0].total_result
        })
    }

    countDossiersClos()
    {
        this.dashboardService.countDossiersClos(this.idService).subscribe(data => {
            this.nb_clos = data[0].total_result
        })
    }

    countSaisinesPrematurees()
    {
        this.dashboardService.countSaisinesPrematurees(this.idService).subscribe(data => {
            this.nb_prematures = data[0].total_result
        })
    }

    getLastDossiers()
    {
        this.dashboardService.getLastDossiers(this.idUser, this.idService).subscribe(data => {
            this.derniers_messages = data.sort((a,b) => {
                //* La liste est filtré du côté client, car en essayant d'utiliser ORDER BY NON_LU DESC dans la requête SQL, dans certains cas, tous les lignes passent à NON_LU = 1 */
                if(a.NON_LU > b.NON_LU) return -1
                else if(a.NON_LU < b.NON_LU) return 1
                else return 0
            })
        })
    }

    goToMessage(ref:string, id_dossier:number)
    {
        this.navigationService.navigateTo(`dossiers/details/ref_${ref}/id_dossier_${id_dossier}/onglet_suivi`, [null])
    }

    goToDossiersHorsDelai()
    {
        this.navigationService.navigateTo(`tableau-de-bord/dossiers-hors-delai`, [null])
    }

    goToMessageNonLus()
    {
        this.navigationService.navigateTo(`tableau-de-bord/messages-non-lus`, [null])
    }

    goToDossiersEnCours()
    {
        this.navigationService.navigateTo(`tableau-de-bord/dossiers-en-cours`, [null])
    }

    goToDossiersClos()
    {
        this.navigationService.navigateTo(`tableau-de-bord/dossiers-clos`, [null])
    }

    goToSaisinesPrematurees()
    {
        this.navigationService.navigateTo(`tableau-de-bord/saisines-prematurees`, [null])
    }
}
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ProfilIn, ProfilOut } from '../../interfaces/Profil';


@Injectable({
  providedIn: 'root'
})
export class ProfilService 
{
  private readonly HOST:string  = environment.BASE_URL

  constructor(
    private httpClient: HttpClient
  ) { }
  

  getProfil(id:number):Observable<ProfilIn[]>
  {
    const url = `${this.HOST}/profil`

    return this.httpClient.get<ProfilIn[]>(url,
      {
        params : 
        {
          id:id
        }
      })
  }

  saveProfil(form:ProfilOut):Observable<number>
  {
    const url = `${this.HOST}/profil`

    return this.httpClient.post<number>(url, form)
  }

  checkPassword(password:string, idUser:number):Observable<boolean>
  {
    const url = `${this.HOST}/profil/check`

    return this.httpClient.post<boolean>(url, {password, idUser})
  }
}

import { Component } from '@angular/core';

@Component({
    selector: 'app-footer',
    standalone:  true,
    templateUrl: 'footer.component.html',
    styleUrl: 'footer.component.css'
})

export class FooterComponent
{
    date:Date = new Date()
    year:string = this.date.getFullYear().toString()
}
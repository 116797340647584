<div class="bg-grey-light connexion-body">
    <form class="card-wrapper" [formGroup]="form">
        <div class="d-flex flex-column">
            <p class="text-center">
                <img class="img-connexion" src="/assets/img/logo-mediation-eau.png" alt="logo-mediation-eau">
            </p>
    
            <div class="input-group" [ngClass]="isInvalid('login') && form.get('login')?.errors ? 'mb-2': 'mb-4'">
                <span class="input-group-text bg-grey-light">
                    <img class="dark-filter" src="/assets/icons/person-fill.svg" alt="">
                </span>
                <input type="text" class="form-control input-text" formControlName="login" placeholder="Identifiant" oninput="this.value = this.value.trimStart()" required>
            </div>
            <span class="red" *ngIf="isInvalid('login') && form.get('login')?.errors"><em><p>* Veuillez entrer une adresse email valide</p></em></span>
    
            <div class="input-group" [ngClass]="isInvalid('password') && form.get('password')?.errors ? 'mb-2': ''">
                <span class="input-group-text bg-grey-light">
                    <img class="dark-filter" src="/assets/icons/lock-fill.svg" alt="">
                </span>
                <input type="password" class="form-control input-text" formControlName="password" placeholder="Mot de passe" (click)="toggleVisibility()" required>
            </div>
            <span class="red" *ngIf="isInvalid('password') && form.get('password')?.errors"><em><p>* Le mot de passe doit avoir 6 caractères minimum</p></em></span>
            
            <div class="my-2" (click)="toggleRememberMe()">
                <input class="" type="checkbox" [checked]="rememberMe">
                &nbsp;
                <label class="form-check-label dark small-text">Se souvenir de moi ?</label>
            </div>
    
            <button class="btn-submit mb-3" (click)="submitForm()">Se connecter</button>
            
            <span class="red" *ngIf="isSubmit && (form.get('login')?.value == '' || form.get('password')?.value == '')"><em><p>* Veuillez entrer un identifiant et un mot de passe</p></em></span>
            <span class="red" *ngIf="isIdentificationError"><em><p>* Identifiant ou mot de passe erroné</p></em></span>

            <button class="btn btn-link blue text-decoration-none small-text" (click)="openDialog()">Mot de passe oublié</button>
              
        </div>
    </form>
</div>

import { HttpErrorResponse, HttpHandlerFn, HttpInterceptorFn, HttpRequest } from "@angular/common/http";
import { ConnexionService } from "../../services/connexion/connexion.service";
import { inject } from "@angular/core";
import { BehaviorSubject, catchError, filter, switchMap, take, takeUntil, throwError } from "rxjs";
import { HttpCancelService } from "../../services/http-cancel/http-cancel.service";

const URL_TO_SKIP = ['connexion','mot-de-passe-oublie','autologin']

export const authInterceptor: HttpInterceptorFn = (req:HttpRequest<unknown>, next:HttpHandlerFn) => {

    const httpCancelService = inject(HttpCancelService)
    const connexionService = inject(ConnexionService)

    connexionService.logoutIfSessionExpires()

    const refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null)
    
    const urlEnd = req.url.split("/").slice(-1).toString()

    if( URL_TO_SKIP.includes(urlEnd) || req.url.includes("autologin") )
    {
        const newRequest = req.clone({headers: req.headers})
        return next(newRequest).pipe(
            takeUntil(httpCancelService.getCancelPendingRequests())
        )
    }
    else
    {
        const isTokenExpired = connexionService.isTokenExpired() ? true : false
        const isRefreshing = isTokenExpired

        const token = isTokenExpired ? sessionStorage.getItem("refresh_token") : sessionStorage.getItem("access_token")

        const authReq = req.clone({
            setHeaders: 
            {
                Authorization: `${token}`
            }
        })

        return next(authReq).pipe(
            takeUntil(httpCancelService.getCancelPendingRequests()),
            catchError((error:HttpErrorResponse) => {
                if (error.status === 401) 
                {
                    return handle401Error(req, next, isRefreshing, refreshTokenSubject)
                } 
                else 
                {
                    return throwError(() => error)
                }
            })
        )
    }
}

function addToken(request: HttpRequest<unknown>, token:string)
{
    return request.clone({
        setHeaders: {'Authorization': `${token}`}
    })
}

function handle401Error(request: HttpRequest<unknown>, next: HttpHandlerFn,  isRefreshing:boolean, refreshTokenSubject: BehaviorSubject<any>)
{
    const connexionService = inject(ConnexionService)

    if(isRefreshing)
    {
        return refreshTokenSubject.pipe(
            filter(token => token != null),
            take(1),
            switchMap(token => {
                return next(addToken(request, token))
            })
        )
    }
    else
    {
        isRefreshing = true
        refreshTokenSubject.next(null)

        return connexionService.refreshToken().pipe(
            switchMap((data: any) => {
                isRefreshing = false
                refreshTokenSubject.next(data.token)
                return next(addToken(request, data.token))
            }),
            catchError((error) => {
                isRefreshing = false
                return throwError(() => error)
            })
        )
    }
}
import { environment } from "../../../environments/environment";

let body:any
let url:string

export function verif() 
{
    return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) 
    {
      const originalMethod = descriptor.value; // Store the original method
  
      descriptor.value = async function (...args: any[]) 
      {
        const params = args[0]

        const componentName = target.constructor.name
        switch (componentName) 
        {
            case "DossiersDetailsComponent":
                url = environment.BASE_URL+"/check-dossier"
                body = bodyDossier(params)
            break;
            case "PartenariatDetailsComponent":
                url = environment.BASE_URL+"/check-dip"
                body = bodyDip(params)
            break;
        }

        const options = 
        {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }
        const fetch_url = await fetch(url, options)
        const response = await fetch_url.json()

        if(!response || response.length == 0)
        {
            localStorage.clear()
            sessionStorage.clear()
        }
        
        // Call the original method
        const result = originalMethod.apply(this, args)
        return result// Return the result of the original method
      }
  
      return descriptor
    }
  }

function bodyDossier(params:{ref:string, id_dossier:number, id_service:string})
{
    return {ref: params.ref , id_dossier: params.id_dossier, id_service:params.id_service}
}

function bodyDip(params:{ref:string, id_dip:number, id_service:string})
{
    return {ref: params.ref , id_dip: params.id_dip, id_service:params.id_service}
}
  
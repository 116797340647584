import { Router, UrlTree } from "@angular/router"
import { NavigationService } from "../../services/navigation/navigation.service"

export class LandingPage
{
  goToLandingPage(
      user_rights: {
        gestion_dossier: boolean,
        copie_message: boolean,
        gestion_convention_facturation: boolean,
        saisie_donnees_abonnes: boolean
      },
      router:Router|NavigationService
    ) {
      const {gestion_dossier, copie_message, gestion_convention_facturation, saisie_donnees_abonnes} = user_rights

      // Fonction pour rediriger en fonction du service utilisé
      const navigate: (url: string) => UrlTree | Promise<boolean> = (url: string) => {
          if (router instanceof NavigationService) 
          {
              return (router as NavigationService).navigateByURL(url, [null]) as unknown as Promise<boolean>
          } 
          else 
          {
              return (router as Router).parseUrl(url) as UrlTree
          }
      }
              
    
      // 1. Vérifier si l'utilisateur a tous les droits
      if (gestion_dossier && copie_message && gestion_convention_facturation && saisie_donnees_abonnes) 
      {
        return navigate('/')
      }
      // 2. Vérifier si l'utilisateur n'a pas les droits 'gestion_dossier' ou 'copie_message'
      else if (!gestion_dossier && !copie_message) 
      {
          if (gestion_convention_facturation) 
          {
              return navigate('/partenariat')
          } 
          else if (saisie_donnees_abonnes) 
          {
              return navigate('/donnees-abonnes')
          } 
          else 
          {
              return navigate('/')
          }
      }
      // 3. Par défaut, rediriger vers "/"
      else 
      {
        return navigate('/')
      }
    }
}
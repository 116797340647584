import { Component, ViewChild } from '@angular/core';
import { DonneesAbonnesService } from '../../services/donnees-abonnes/donnees-abonnes.service';
import { DonneesAbonnes, RaisonMaj } from '../../interfaces/DonneesAbonnes';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { fileValidator } from '../../helpers/custom-validators/file-validator';
import { SharedModule } from '../../modules/shared/shared.module';
import { MatTableDataSource } from '@angular/material/table';
import { UploadService } from '../../services/upload/upload.service';
import { SharedService } from '../../services/shared/shared.service';
import { DimensionnementPerimetre } from '../../pages/dimensionnement-perimetre.component';
import { FormatNumberPipe } from "../../helpers/pipes/format-number/format-number.pipe";
import { BreadcrumbComponent } from "../../common/breadcrumb/breadcrumb.component";
import { MatSort } from '@angular/material/sort';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
    selector: 'app-donnees-abonnes',
    standalone: true,
    templateUrl: './donnees-abonnes.component.html',
    styleUrl: './donnees-abonnes.component.css',
    imports: [SharedModule, FormatNumberPipe, BreadcrumbComponent]
})

export class DonneesAbonnesComponent
{
  title:string = "Données abonnés"
  fileName:string = "Aucun fichier choisi"
  isColumnVisible:boolean = true
  date:Date = new Date()
  year:number = this.date.getFullYear()
  selectOptions!:RaisonMaj[]
  row_copy = null
  editMode:boolean = false
  isInputFileVisible:boolean = false
  form!: FormGroup
  idService!:number
  idUser!:number
  displayedColumns: string[] = ["annee", "abonnes_eau_potable", "abonnes_eau_brute", "abonnes_assainissement_collectif", "abonnes_assainissement_non_collectif", "total_abonnes", "date_effet","raison_maj","empty"]
  allowedTypes = [".csv", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"]
  ELEMENT_DATA!: DonneesAbonnes[]
  dataSource!:MatTableDataSource<DonneesAbonnes>
  total_abonnes!:number
  is_convention:boolean
  can_edit_abo:boolean
  default_raison_maj:boolean = false
  fileEmpty:boolean = true
  isSubmitted:boolean = false
  currentRow!:number
  originalData!: DonneesAbonnes[]
  @ViewChild(MatSort) sort!: MatSort

  constructor
  (
    private donneesAbonnesService: DonneesAbonnesService,
    private sharedService: SharedService,
    private uploadService: UploadService,
    private snackBar: MatSnackBar
  ) 
  {
    const id_user = this.sharedService.getIdUser()
    this.idUser = id_user

    const id_service = this.sharedService.getIdService()
    this.idService = id_service

    const userRights = this.sharedService.getUserRights()

    const is_convention = userRights.gestion_convention_facturation
    this.is_convention = is_convention

    const can_edit_abo = userRights.saisie_donnees_abonnes
    this.can_edit_abo = can_edit_abo

    this.form = new FormGroup({
      annee: new FormControl([Validators.required]),
      abonnes_eau_potable: new FormControl([Validators.min(0),Validators.required]),
      abonnes_eau_brute: new FormControl([Validators.min(0),Validators.required]),
      abonnes_assainissement_collectif: new FormControl([Validators.min(0),Validators.required]),
      abonnes_assainissement_non_collectif: new FormControl([Validators.min(0),Validators.required]),
      date_effet: new FormControl([Validators.required]),
      raison_maj: new FormControl(),
      file: new FormControl(null /*, [fileValidator()] if file required*/)
    })
  }
  

  ngOnInit()
  {
    this.getRaisonMajList()
    this.getDataDonneesAbonnes()

    this.form.valueChanges.subscribe( value => {
      this.calculTotal()
    })
  }

  getRaisonMajList()
  {
    this.donneesAbonnesService.getRaisonMajList().subscribe(data => {
      this.selectOptions = data
      this.form.get('raison_maj')?.patchValue(data[0]?.id_raison_maj)
    })
  }

  getDataDonneesAbonnes(search:string="")
  {
    this.donneesAbonnesService.getData(this.idService, this.idUser, search).subscribe(data => {

      this.originalData = data
      this.ELEMENT_DATA = data
      this.dataSource = new MatTableDataSource<DonneesAbonnes>(this.ELEMENT_DATA)
      this.dataSource.sort = this.sort

      this.form.get("annee")?.patchValue(data[0]?.annee)
      this.form.get("abonnes_eau_potable")?.patchValue(data[0]?.abonnes_eau_potable)
      this.form.get("abonnes_eau_brute")?.patchValue(data[0]?.abonnes_eau_brute)
      this.form.get("abonnes_assainissement_collectif")?.patchValue(data[0]?.abonnes_assainissement_collectif)
      this.form.get("abonnes_assainissement_non_collectif")?.patchValue(data[0]?.abonnes_assainissement_non_collectif)
      // this.form.get("date_effet")?.patchValue(new Date(data[0]?.annee, 0).toLocaleDateString().split("/").reverse().join("-")) // Valeur par défaut de la date d'effet au 1er de l'année

      this.form.get("raison_maj")?.patchValue(5) // Valeur par défaut 'A mettre à jour'

      this.total_abonnes = data[0]?.abonnes_eau_potable + data[0]?.abonnes_eau_brute + data[0]?.abonnes_assainissement_collectif + data[0]?.abonnes_assainissement_non_collectif
    })
  }

  updateValueRow(index:number, search:string="")
  {
    this.donneesAbonnesService.getData(this.idService, this.idUser, search).subscribe(data => {

      this.originalData = data
      this.ELEMENT_DATA = data
      this.dataSource = new MatTableDataSource<DonneesAbonnes>(this.ELEMENT_DATA)
      this.dataSource.sort = this.sort

      this.form.get("annee")?.patchValue(data[index]?.annee)
      this.form.get("abonnes_eau_potable")?.patchValue(data[index]?.abonnes_eau_potable)
      this.form.get("abonnes_eau_brute")?.patchValue(data[index]?.abonnes_eau_brute)
      this.form.get("abonnes_assainissement_collectif")?.patchValue(data[index]?.abonnes_assainissement_collectif)
      this.form.get("abonnes_assainissement_non_collectif")?.patchValue(data[index]?.abonnes_assainissement_non_collectif)
      // this.form.get("date_effet")?.patchValue(new Date(data[index]?.annee, 0, 1).toLocaleDateString().split("/").reverse().join("-")) // Valeur par défaut de la date d'effet au 1er de l'année
      this.form.get("date_effet")?.patchValue(data[index]?.annee.toString().concat("-01-01"))

      this.form.get("raison_maj")?.patchValue(5) // Valeur par défaut 'A mettre à jour'

      this.total_abonnes = data[index]?.abonnes_eau_potable + data[index]?.abonnes_eau_brute + data[index]?.abonnes_assainissement_collectif + data[index]?.abonnes_assainissement_non_collectif
    })
  }

  onFileSelected(event:Event)
  {
    const inputFileElement = event.target as HTMLInputElement

    if( inputFileElement.files && inputFileElement.files.length > 0 )
    {
      const maxSize = 10 * 1024 * 1024

      if(!this.allowedTypes.includes(inputFileElement.files[0].type))
      {
        return alert("Ce type de fichier n'est pas pris en compte")
      }

      if(inputFileElement.files[0].size > maxSize)
      {
        return alert('taille de fichier supérieure à 10 Mo')
      }

      this.fileName = inputFileElement.files[0].name
      this.form.get(`file`)?.patchValue(inputFileElement.files[0])
    }

    this.fileEmpty = false
  }

  editRow(index:number)
  {
    this.currentRow = index
    this.isColumnVisible = false
    this.editMode = true
    this.processValue(index)
  }

  processValue(index:number)
  {
    this.form.get("annee")?.patchValue(this.originalData[index]?.annee)
    this.form.get("abonnes_eau_potable")?.patchValue(this.originalData[index]?.abonnes_eau_potable)
    this.form.get("abonnes_eau_brute")?.patchValue(this.originalData[index]?.abonnes_eau_brute)
    this.form.get("abonnes_assainissement_collectif")?.patchValue(this.originalData[index]?.abonnes_assainissement_collectif)
    this.form.get("abonnes_assainissement_non_collectif")?.patchValue(this.originalData[index]?.abonnes_assainissement_non_collectif)
    this.form.get("date_effet")?.patchValue(this.originalData[index]?.annee.toString().concat("-01-01"))

    this.total_abonnes = this.originalData[index]?.abonnes_eau_potable + this.originalData[index]?.abonnes_eau_brute + this.originalData[index]?.abonnes_assainissement_collectif + this.originalData[index]?.abonnes_assainissement_non_collectif
  }

  closeEditRow()
  {
    this.isColumnVisible = true
    this.editMode = false
  }

  canEditAbo()
  {
    return this.can_edit_abo || this.is_convention ? true : false
  }

  preventTextInput(event: KeyboardEvent)
  {
    const array_number = [1,2,3,4,5,6,7,8,9,0]
    const keyboardKey = ["Backspace","Delete","Tab","ArrowUp","ArrowDown","ArrowLeft","ArrowRight"]
    return array_number.includes(Number(event.key)) || keyboardKey.includes(event.key)
  }

  calculTotal()
  {
    const form = this.form.value
    this.total_abonnes = Number(form.abonnes_eau_potable) + Number(form.abonnes_eau_brute) + Number(form.abonnes_assainissement_collectif) + Number(form.abonnes_assainissement_non_collectif)
  }

  changeRaisonMaj(event:Event)
  {
    this.isSubmitted = false
    if(event.target instanceof HTMLSelectElement && event.target.value === "3")
    {
      this.sharedService.openDialog(DimensionnementPerimetre)
    }
  }

  clearForm()
  {
    this.form.reset()
    this.fileName = "Aucun fichier choisi"
  }

  async postData(id_abonnement_eau:number)
  {
    this.isSubmitted = true
    const fileExists = this.form.get("file")?.value ? true : false

    if( this.form.get('raison_maj')?.value == 5) // ID 5 : A mettre à jour
    {
      this.default_raison_maj = true
      return
    }
    if( this.form.get('raison_maj')?.value == 3 && !fileExists) // ID 3 : Elargissement / Restriction périmètre
    {
      this.fileEmpty = true
      return
    }
    if( this.form.invalid || this.form.get('raison_maj')?.value == 5)
    {
      this.form.markAsDirty()
      this.form.markAllAsTouched()
      console.log("invalid")
      return
    }

    const form = this.form.value

    form.id_abonnement_eau = Number(id_abonnement_eau)
    form.annee = Number(form.annee)
    form.abonnes_eau_potable = Number(form.abonnes_eau_potable)
    form.abonnes_eau_brute = Number(form.abonnes_eau_brute)
    form.abonnes_assainissement_collectif = Number(form.abonnes_assainissement_collectif)
    form.abonnes_assainissement_non_collectif = Number(form.abonnes_assainissement_non_collectif)
    form.total_abonnes = Number(form.total_abonnes)
    form.id_service = this.idService
    form.id_user = this.idUser
    form.fileName = this.fileName == 'aucun fichier choisi' ? '' : this.fileName

    const folder = "imports"
    const formData = new FormData()
    if(form.file) formData.append('files', form.file)
    
    this.donneesAbonnesService.updateData(form).subscribe( async(data) => {

      if( data && form.file )
      {        
        formData.append("folder", folder)
        formData.append("subFolder", "")
        formData.append("id_user", this.idUser.toString())
        formData.append("id_dossier", "0")
        formData.append("id_service", this.idService.toString())
        formData.append("isDonneesAbonnes", "true")

        ;(await this.uploadService.uploadFile(formData)).subscribe({
          next: (data) => 
          {
            this.snackBar.open("Document transmis", "Ok", {
              duration: 3000,
            })
          },
          error: (e:Error) => 
          {
            console.log(e)
            this.snackBar.open("Une erreur est survenue lors de la transmission de votre document", "J'ai compris", {
              duration: 5000,
            })
          }
        })
      }

      this.isColumnVisible = true
      this.editMode = false
      this.sharedService.postTableConnexion(this.idUser, 498, this.idUser).subscribe()

      this.clearForm()

      this.updateValueRow(this.currentRow)
    })
  }

  goToContactPage()
  {
    this.sharedService.goToContactPage()
  }

  switchToTabletMode()
  {
    return this.sharedService.switchToTabletMode()
  }
}




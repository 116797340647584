import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SharedService } from '../shared/shared.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadService 
{
  private readonly HOST:string = environment.BASE_URL

  constructor
  (
    private httpClient:HttpClient,
    private sharedService: SharedService
  ) { }

  checkDownloadable(ref:string[], subFolder:string):Observable<Document[]>
  {
    const url = `${this.HOST}/check-downloadable`
    return this.httpClient.get<Document[]>(url, 
    {
      params : 
      {
        ref,
        path:subFolder
      }
    })
  }


  // downloadProcess(payload:{ref:string, fileName:string, type:string, path:string, id_service?:string, liste_pj?:string[]})
  // {
  //   this.sharedService.blurPage()

  //   // POUR LES TESTS EN LOCAL
  //   const ws = new WebSocket(`ws://${this.WS_URL}:${this.PORT}`)
  //   // const ws = new WebSocket(`wss://${this.WS_URL}:${this.PORT}`)

  //   ws.addEventListener("open", () =>{
  //     ws.send(JSON.stringify(payload))
  //   })

  //   ws.addEventListener('message', function (event) {
  //     // console.log(event.data)
  //     // console.log(JSON.parse(event.data))
  //     // return

  //     const buffer = (JSON.parse(event.data)["data"])
      
  //     const mimeTypeToUse = (payload.type == "all_pj_message" || payload.type == "all_pj_message_partenariat") ? 'application/zip' : 'application/octet-stream'

  //     const binary = new Uint8Array(buffer).reduce((acc, byte) => acc + String.fromCharCode(byte), '')
  //     const base64 = btoa(binary)

  //     const dataUrl = `data:${mimeTypeToUse};base64,${base64}`

  //     const a = document.createElement('a')
  //     a.href = dataUrl
  //     a.download = (payload.type == "all_pj_message" || payload.type == "all_pj_message_partenariat") ? payload.ref : payload.fileName
  //     a.click()
  //   })
  // }

  downloadProcess(payload:{ref:string, fileName:string, type:string, path:string, id_service?:string, liste_pj?:string[], service?:string})
  {
    this.sharedService.blurPage()

    this.download(payload).subscribe((blob:Blob) => this.downloadBlob(blob, payload))
  }

  download(payload:{ref:string, fileName:string, type:string, path:string, id_service?:string, liste_pj?:string[]}): Observable<Blob>
  {
    const url = `${this.HOST}/download`

    return this.httpClient.get(url, 
      {
        params:
        {
          payload:JSON.stringify(payload)
        },
        responseType: 'blob'
      }
    )
  }

  downloadBlob(blob:Blob, payload:{ref:string, fileName:string, type:string, path:string, id_service?:string, liste_pj?:string[], service?:string})
  {
    if( blob?.size == 0 )
    {
      alert("fichier non trouvé")
      return this.sharedService.focusPage()
    }

    const blobUrl = URL.createObjectURL(blob)
    const link = document.createElement("a")


    link.href = blobUrl

    if(payload.type == "all_pj_message") 
    {
      link.download = payload.ref
    }
    else if(payload.type == "all_pj_message_partenariat") 
    {
      link.download = String(payload.service)
    }
    else if(payload.type == "dip_convention") 
    {
      link.download = payload.fileName.split("-")[0]
    }
    else
    {
      link.download = payload.fileName
    }
    link.click()
    window.URL.revokeObjectURL(link.toString())

    this.sharedService.focusPage()
  }

  // downloadProcessFacturation(ref:string, fileName:string, subFolder:string, idUser:number, idItem:number)
  // {
  //   this.sharedService.blurPage()

  //   this.download("facturation", ref.toString(), fileName, subFolder, idUser, idItem)
  //   // this.downloadFacturation(ref.toString(), fileName, subFolder).subscribe((blob:Blob) => this.downloadBlob(blob, fileName, idUser, idItem))
  // }

  // downloadProcessPartenariat(id_service:number, fileName:string, subFolder:string, idUser:number, idItem:number)
  // {
  //   this.sharedService.blurPage()

  //   this.download("partenariat", id_service.toString(), fileName, subFolder, idUser, idItem)
  //   // this.downloadPartenariat(id_service, fileName, subFolder).subscribe((blob:Blob) => this.downloadBlob(blob, fileName, idUser, idItem))
  // }

  // downloadProcessDipConvention(id_service:number, fileName:string, subFolder:string, idUser:number, idItem:number)
  // {
  //   this.sharedService.blurPage()

  //   this.download("dip_convention", id_service.toString(), fileName, subFolder, idUser, idItem)
  //   // this.downloadDIPConvention(id_service, fileName, subFolder).subscribe((blob:Blob) => this.downloadBlob(blob, fileName, idUser, idItem))
  // }

  // download(type:string, ref:string, fileName:string, subFolder:string, idUser:number, idItem:number)
  // {
  //   const ws = new WebSocket("ws://localhost:8082")

  //   ws.addEventListener("open", () =>{
  //     console.log("Connection to websocket")

  //     const payload = {type, ref, fileName, subFolder}
  //     ws.send(JSON.stringify(payload))
  //   })

  //   ws.addEventListener('message', function (event) {
  //     // console.log(event.data)
  //     const buffer = (JSON.parse(event.data)["data"])
      
  //     const mimeTypeToUse = 'application/octet-stream'

  //     const binary = new Uint8Array(buffer).reduce((acc, byte) => acc + String.fromCharCode(byte), '')
  //     const base64 = btoa(binary)

  //     const dataUrl = `data:${mimeTypeToUse};base64,${base64}`

  //     const a = document.createElement('a')
  //     a.href = dataUrl
  //     a.download = fileName
  //     a.click()
  //   })

  //   this.sharedService.postTableConnexion(idUser, 434, idItem).subscribe()
  // }

  // downloadAllAttachments(type:string, ref:string, path:string, idUser:number, liste_pj:string)
  // {
  //   this.sharedService.blurPage()

  //   const ws = new WebSocket("ws://localhost:8084")

  //   ws.addEventListener("open", () =>{
  //     const payload = {type, ref, path, liste_pj}
  //     ws.send(JSON.stringify(payload))
  //   })

  //   ws.addEventListener('message', function (event) {
  //     // console.log(event.data)

  //     const  service = (type == "all_partenariat") ? JSON.parse(event.data)["service"] : null

  //     const buffer = JSON.parse(JSON.parse(event.data).data)['data']
      
  //     const mimeTypeToUse = 'application/zip'

  //     const binary = new Uint8Array(buffer).reduce((acc, byte) => acc + String.fromCharCode(byte), '')
  //     const base64 = btoa(binary)

  //     const dataUrl = `data:${mimeTypeToUse};base64,${base64}`

  //     const a = document.createElement('a')
  //     a.href = dataUrl
  //     a.download = (type == "all_partenariat") ? service : ref
  //     a.click()
  //   })

  //   this.sharedService.postTableConnexion(idUser, 434, idUser).subscribe()
  // }


  // downloadBlob(blob:Blob, fileName:string, idUser:number, idItem:number)
  // {
  //   if( blob?.size == 0 )
  //   {
  //     alert("fichier non trouvé")
  //     return this.sharedService.focusPage()
  //   }

  //   const blobUrl = URL.createObjectURL(blob)
  //   const link = document.createElement("a")

  //   link.href = blobUrl
  //   link.download = fileName
  //   link.click()
  //   window.URL.revokeObjectURL(link.toString())

  //   this.sharedService.focusPage()

  //   this.sharedService.postTableConnexion(idUser, 434, idItem).subscribe()

  // }
  
  // downloadFacturation(ref:string, fileName:string, subFolder:string): Observable<Blob>
  // {
  //   const url = `${this.HOST}/downloadFacturation`

  //   return this.httpClient.get(url, 
  //     {
  //       params:
  //       {
  //         ref: ref,
  //         fileName: fileName,
  //         subFolder: subFolder
  //       },
  //       responseType: 'blob'
  //     }
  //   )
  // }

  // downloadPartenariat(id_service:number, fileName:string, folder:string): Observable<Blob>
  // {
  //   const url = `${this.HOST}/downloadPartenariat`

  //   return this.httpClient.get(url, 
  //     {
  //       params:
  //       {
  //         id_service,
  //         fileName,
  //         folder
  //       },
  //       responseType: 'blob'
  //     }
  //   )
  // }

  // downloadDIPConvention(id_service:number, fileName:string, folder:string): Observable<Blob>
  // {
  //   const url = `${this.HOST}/downloadDIP`

  //   return this.httpClient.get(url, 
  //     {
  //       params:
  //       {
  //         id_service,
  //         fileName,
  //         folder
  //       },
  //       responseType: 'blob'
  //     }
  //   )
  // }

  // download(ref:string, fileName:string, subFolder:string): Observable<Blob>
  // {
  //   const url = `${this.HOST}/download`

  //   return this.httpClient.get(url, 
  //     {
  //       params:
  //       {
  //         ref: ref,
  //         fileName: fileName,
  //         subFolder: subFolder
  //       },
  //       responseType: 'blob'
  //     }
  //   )
  // }

  // downloadAllAttachments(ref:string, path:string, list_pj:string):Observable<Blob>
  // {
  //   const url = `${this.HOST}/download/all`

  //   return this.httpClient.get(url, 
  //     {
  //       params : 
  //       {
  //         ref:ref,
  //         path:path,
  //         list_pj:list_pj
  //       },
  //       responseType: 'blob'
  //     })
  // }
}

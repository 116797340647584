import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPhoneNumber',
  standalone: true
})
export class FormatPhoneNumberPipe implements PipeTransform 
{

  transform(value: number|string): unknown 
  {
    if(!value) return

    const stringValue = value.toString()
    return stringValue.replace(/(\d)(?=(\d{2})+(\d{2})?$)/g, '$1 ')
  }

}

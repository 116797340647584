<main class="main table">
    <app-breadcrumb [title]="parent + ' > ' + title"></app-breadcrumb>

    <!-- <h2 class="grey-dark">{{ title }} - {{ civilite }} {{ abonne }} </h2> -->
    <h2 class="grey-dark">{{ title }} - {{ abonne }} </h2>
    <!-- <span class="avertissement-hors-delai small" *ngIf="hors_delai">Ce dossier est hors délai</span> -->

    <section class="infos grey-dark row mt-2">
        <div class="col-4">
            <small *ngIf="num_contrat1">Numéro de Contrat @if(num_contrat1 && (num_contrat1 != num_contrat2)){1}: <span>{{ num_contrat1 }}</span></small>
            <small *ngIf="num_contrat2 && (num_contrat1 != num_contrat2)">Numéro de Contrat 2: <span>{{ num_contrat2 }}</span></small>
            <small *ngIf=" adresse != ''">Adresse du litige: 
              <span>{{ adresse }}</span> 
              <span *ngIf=" cp != ''"> - {{ cp }}</span> 
              <span *ngIf=" ville != ''">&nbsp; {{ ville }}</span>
            </small>
        </div>
        <div class="col-4">
            <small>Date saisine: <span>{{ date | date :'dd-MM-yyyy' }}</span></small>
            <small class="bold">État: 
              <span>{{ etat }}</span> 
              <span *ngIf="etat?.toLowerCase()?.includes('ep')">
                <a class="white infobulle" >
                  <span class="indication grey">
                      <span class="icon">
                          <span class="toolTipAction">
                              <img class="picto mx-2" src="/assets/img/picto-question.png" alt="question" height="15">
                              <span class="small bg-grey-light blue-dark-1 p-2 toolTipContent">
                                  <img src="/assets/icons/picto-info.png" alt="" height="15"> EP: Etude préalable
                              </span>
                          </span>
                      </span>
                  </span>
                </a>
              </span>
            </small>
        </div>
    </section>

    <section class="mt-5">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item" *ngIf="gestion_dossier || copie_message">
              <button class="nav-link h-100 active" id="suivi" data-bs-toggle="tab" data-bs-target="#suivi-pane" type="button" role="tab" aria-controls="suivi-pane" aria-selected="true" (click)="changeTab('suivi')">Suivi</button>
            </li>
            <li class="nav-item" *ngIf="gestion_dossier || copie_message">
              <button class="nav-link h-100" id="documents" data-bs-toggle="tab" data-bs-target="#documents-pane" type="button" role="tab" aria-controls="documents-pane" aria-selected="false" (click)="changeTab('documents')">Documents</button>
            </li>
            <li class="nav-item" *ngIf="gestion_dossier || copie_message">
              <button class="nav-link h-100" id="messages" data-bs-toggle="tab" data-bs-target="#messages-pane" type="button" role="tab" aria-controls="messages-pane" aria-selected="false" (click)="changeTab('messages')">Messages</button>
            </li>
            <li class="nav-item" *ngIf="gestion_dossier || copie_message">
              <button class="nav-link h-100" id="transmettre" data-bs-toggle="tab" data-bs-target="#transmettre-pane" type="button" role="tab" aria-controls="transmettre-pane" aria-selected="false" (click)="changeTab('transmettre')">
                Transmettre 
                <img [src]="imgSource" alt="icone transmettre" height="20">
              </button>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="suivi-pane" role="tabpanel" aria-labelledby="suivi" tabindex="0">
              <app-suivi [data]="parentData"></app-suivi>
            </div>
            <div class="tab-pane fade" id="documents-pane" role="tabpanel" aria-labelledby="documents" tabindex="0">
              <app-document [data]="parentData"></app-document>
            </div>
            <div class="tab-pane fade" id="messages-pane" role="tabpanel" aria-labelledby="messages" tabindex="0">
              <app-message [data]="parentData"></app-message>
            </div>
            <div class="tab-pane fade" id="transmettre-pane" role="tabpanel" aria-labelledby="transmettre" tabindex="0">
              <app-transmettre [data]="parentData" [ref]="ref" [liste_copie_message]="liste_copie_message" [id_dossier]="id_dossier"></app-transmettre>
            </div>
          </div>
    </section>
    
</main>

<main class="main table">
  <app-breadcrumb [title]="parent + ' > ' + title"></app-breadcrumb>

  <h2 class="grey-dark">{{ title }} - {{ service }} </h2>

  <section class="infos grey-dark row mt-2">
      <div class="col-3">
          <small>Nom: <span>{{ nom }}</span></small>
          <small>Téléphone: <span>{{ telephone | formatPhoneNumber }}</span></small>
          <small>Email: <span>{{ email }}</span></small>
      </div>
      <div class="col-3">
          <small *ngIf="date_dip">Date DIP* signée: <span>{{ date_dip | date:'dd-MM-yyyy' }}</span></small>
          <small *ngIf="date_convention">Date convention signée: <span>{{ date_convention | date:'dd-MM-yyyy' }}</span></small>
          <i><small>* DIP: Demande d'Intention de Partenariat</small></i>
      </div>
      <div class="button-group col-2">
          <button type="button" class="infos-button" *ngIf="is_dip_downloadable" (click)="downloadDIP('dip-'+idService+'.pdf', idUser)" >Télécharger la DIP*</button>
          <button type="button" class="infos-button" *ngIf="is_convention_downloadable" (click)="downloadDIP('convention-'+idService+'.pdf', idUser)" >Télécharger la Convention</button>
      </div>
  </section>

  <section class="mt-5">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item" *ngIf="gestion_dossier || copie_message">
            <button class="nav-link active" id="suivi" data-bs-toggle="tab" data-bs-target="#suivi-pane" type="button" role="tab" aria-controls="suivi-pane" aria-selected="true" (click)="changeTab('suivi')">Suivi</button>
          </li>
          <li class="nav-item" *ngIf="gestion_dossier || copie_message">
            <button class="nav-link" id="documents" data-bs-toggle="tab" data-bs-target="#documents-pane" type="button" role="tab" aria-controls="documents-pane" aria-selected="false" (click)="changeTab('documents')">Documents</button>
          </li>
          <li class="nav-item" *ngIf="gestion_dossier || copie_message">
            <button class="nav-link" id="messages" data-bs-toggle="tab" data-bs-target="#messages-pane" type="button" role="tab" aria-controls="messages-pane" aria-selected="false" (click)="changeTab('messages')">Messages</button>
          </li>
          <li class="nav-item" *ngIf="gestion_dossier">
            <button class="nav-link" id="transmettre" data-bs-toggle="tab" data-bs-target="#transmettre-pane" type="button" role="tab" aria-controls="transmettre-pane" aria-selected="false" (click)="changeTab('transmettre')">
              Transmettre 
              <img [src]="imgSource" alt="icone transmettre" height="20">
            </button>
          </li>
        </ul>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="suivi-pane" role="tabpanel" aria-labelledby="suivi" tabindex="0">
            <app-suivi [data]="parentData"></app-suivi>
          </div>
          <div class="tab-pane fade" id="documents-pane" role="tabpanel" aria-labelledby="documents" tabindex="0">
            <app-document [data]="parentData"></app-document>
          </div>
          <div class="tab-pane fade" id="messages-pane" role="tabpanel" aria-labelledby="messages" tabindex="0">
            <app-message [data]="parentData"></app-message>
          </div>
          <div class="tab-pane fade" id="transmettre-pane" role="tabpanel" aria-labelledby="transmettre" tabindex="0">
            <app-transmettre [data]="parentData" [liste_copie_message]="liste_copie_message" [ref]="getIdService()"></app-transmettre>
          </div>
        </div>
  </section>
    
  <i><small>* DIP: Demande d'Intention de Partenariat</small></i>
</main>

<div class="pagination-wrapper" *ngIf="totalResult > 7 && isPaginationVisible">
    <div class="pagination">
        <span (click)="firstPage()" [ngClass]="currentPage == 1 ? 'd-none' : '' "><<</span>
        <span (click)="previousPage()" [ngClass]="currentPage == 1 ? 'd-none' : '' "><</span>
        <ng-container *ngFor="let page of getPages();let index">
            <span [ngClass]="{'currentPage': page.isCurrentPage}" (click)="changePage(page.pageNumber)">{{ page.pageNumber }}</span>
          </ng-container>
        <span (click)="nextPage()" [ngClass]="currentPage == totalPages ? 'd-none' : '' ">></span>
        <span (click)="lastPage()" [ngClass]="currentPage == totalPages ? 'd-none' : '' ">>></span>
    </div>
</div>
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { NavigationService } from '../services/navigation/navigation.service';

@Component({
  standalone: true,
  imports: [CommonModule],
  template: `
  <div class="dialog d-flex">
    <div class="col-4 bg-blue white bold dialog-title">Changement de mot de passe</div>
    <div class="col-8 bg-white blue-dark-1 dialog-content-group">
        <div class="dialog-content">
            <p>Vous venez de vous connecter à votre espace avec le mot de passe par défaut fourni par la Médiation de l'eau.</p>
            <p>Il est vivement conseillé de remplacer ce mot de passe pour des raisons de sécurité</p>
            <p>En cliquant sur le bouton ci-dessous, vous serez dirigé vers votre page de profil où vous pourrez changer votre mot de passe</p>

            <button class="change-password float-end" (click)="navigateTo()">
                Allez à la page de profil
                <img class="white-filter mx-1" src="/assets/icons/triangle.png" style="transform: rotate(-90deg)" height="15">
            </button>
        </div>
    </div>
  </div>
  `
})

export class ChangePassword
{

    constructor
    (
        private navigationService: NavigationService,
        private dialog: MatDialog,
    ){}

    navigateTo()
    {
        this.navigationService.navigateTo("profil",[null]) 
        this.closeDialog()
    }

    closeDialog()
    {
        this.dialog.closeAll()
    }
}

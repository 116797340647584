import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { DonneesAbonnes, RaisonMaj } from '../../interfaces/DonneesAbonnes';

@Injectable({
  providedIn: 'root'
})
export class DonneesAbonnesService 
{
  private readonly HOST:string  = environment.BASE_URL

  constructor(
    private httpClient:HttpClient
  ) { }

  getRaisonMajList():Observable<RaisonMaj[]>
  {
    const url = `${this.HOST}/donnees-abonnes/raison_maj_abonnement_eau`

    return this.httpClient.get<RaisonMaj[]>(url)
  }

  getData(id_service:number,id_user:number,search:string=""):Observable<DonneesAbonnes[]>
  {
    const url = `${this.HOST}/donnees-abonnes`

    return this.httpClient.get<DonneesAbonnes[]>(url, 
      {
        params : 
        {
          id_service:id_service,
          id_user:id_user,
          search:search
        }
      })
  }

  updateData(data:DonneesAbonnes):Observable<number>
  {
    const url = `${this.HOST}/donnees-abonnes`

    return this.httpClient.put<number>(url,data)
  }
}

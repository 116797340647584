import { Component, SimpleChanges } from '@angular/core';
import { PartenariatService } from '../../../../services/partenariat/partenariat.service';
import { SharedService } from '../../../../services/shared/shared.service';
import { SharedModule } from '../../../../modules/shared/shared.module';
import { Partenariat } from '../../../../interfaces/Partenariat';
import { BreadcrumbComponent } from "../../../../common/breadcrumb/breadcrumb.component";
import { getElementsOnPage } from '../../../../helpers/functions/GetElementsOnPage';

@Component({
    selector: 'app-partenariat-liste',
    standalone: true,
    templateUrl: './partenariat-liste.component.html',
    styleUrl: './partenariat-liste.component.css',
    imports: [SharedModule, BreadcrumbComponent]
})
export class PartenariatListeComponent 
{
  title:string = "Partenariat" 
  pageSize:number = getElementsOnPage()
  pageIndex:number = 1
  idService!:number
  totalPages!:number
  totalResult!:number

  data_header_table:object = 
  {
    // "badge":"",
    "ref_dip":"Suivi de la convention",
    "date_dip":"Date DIP signée",
    "date_convention":"Date Convention signée",
    "doc_non_lus":"Documents non lus",
    "msg_non_lus":"Messages non lus"
  }
  data_body_table: Partenariat[] = []
  isDataReady:boolean = false

  constructor
  (
    private partenariatService:PartenariatService,
    private sharedService: SharedService
  ) 
  {        
    const id_service = this.sharedService.getIdService()
    this.idService = id_service
    this.createPagination(this.pageSize)
  }

  ngOnChanges(changes: SimpleChanges) 
  {
    if ('search' in changes) 
    {
      this.getDataPartenariat()
    }
  }

  changePage(currentPage:number, pageSize:number=this.pageSize, changeDisplay:boolean=false)
  {  
    this.pageIndex = changeDisplay == false ? currentPage : 1 
    this.pageSize = pageSize
    this.getDataPartenariat()
  }

  async createPagination(pageSize:number)
  {
    (await
      this.sharedService.getInfoPagination("partenariat_liste", this.idService)).subscribe(data => {
      this.totalResult = data[0]?.total_result
      this.totalPages = Math.ceil(this.totalResult / pageSize)
    })
  }

  ngOnInit()
  {
    this.getDataPartenariat()
  }

  getDataPartenariat(search:string="")
  {
    this.partenariatService.getData(this.pageIndex, this.pageSize, this.idService, search).subscribe(data => {
      this.data_body_table = data
      this.isDataReady = true
    })
  }
}

<form class="mt-3 mb-1 searchForm float-end" [formGroup]="searchForm" *ngIf="gestion_dossier || copie_message">

    <div class="d-flex align-items-center justify-content-end" *ngIf="!hideComponent">
        <div 
            class="small bold grey-dark cursor col-4 text-end me-2" 
            *ngIf="last_item && last_item_id" 
            (click)="goToDetails(last_item, last_item_id, last_item_type)">
                {{ 'N°'.concat(last_item) }}
        </div>
        <input 
            #inputSearch
            formControlName="searchValue"
            type="text" 
            class="rounded-1 ps-3 py-1 border border-dark-subtle border-1" 
            placeholder="Rechercher un dossier" 
            aria-label="Recherche"
            autocomplete="off"
            (focus)="getRefDossiers(inputSearch.value)"
            >
        <img class="grey-filter search-dossier-close-icon cursor" src="/assets/icons/picto-croix-rouge.png" height="15" alt="rechercher" (click)="inputSearch.value = ''" *ngIf="inputSearch.value.length > 0">
    </div>

    <ul class="bg-grey-light position-absolute z-3 search-dossiers-list" *ngIf="isVisible">
        @for (item of liste_dossiers; track $index) 
        {
            <li class="border border-bottom cursor search-dossiers-item py-2 px-1" (click)="goToDetails(item.ref, item.id, item.type)">
                <span class="small white bold p-1 rounded-1" [ngClass]="item.type == 'dossiers' ? 'bg-blue': 'bg-red'">{{ item.ref }}</span>
                <span class="small grey-dark mx-1">({{ item.abonne }})</span>
            </li>
        }
    </ul>
</form>

// ANGULAR MODULE
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// COMPONENT
import { SidebarComponent } from '../../common/sidebar/sidebar.component';
import { HeaderComponent } from '../../common/header/header.component';
import { FooterComponent } from '../../common/footer/footer.component';
import { TableComponent } from '../../common/table/table.component';
import { ElementsOnTableComponent } from '../../common/elements-on-table/elements-on-table.component';
import { PaginationComponent } from '../../common/pagination/pagination.component';
import { SearchbarComponent } from '../../common/searchbar/searchbar.component';
import { ConnexionComponent } from '../../connexion/connexion.component';

// ANGULAR MATERIAL MODULE
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSortModule } from '@angular/material/sort';
import { BreadcrumbComponent } from '../../common/breadcrumb/breadcrumb.component';
import {MatFormFieldModule} from '@angular/material/form-field';

@NgModule({
  declarations: [],
  imports: 
  [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatDialogModule,
    MatTooltipModule,
    MatSortModule,
    MatFormFieldModule,
    RouterModule,
    ConnexionComponent,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    TableComponent,
    BreadcrumbComponent,
    ElementsOnTableComponent,
    PaginationComponent,
    SearchbarComponent
  ],
  exports:
  [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatDialogModule,
    MatTooltipModule,
    MatSortModule,
    MatFormFieldModule,
    RouterModule,
    ConnexionComponent,
    SidebarComponent,
    HeaderComponent,
    FooterComponent,
    TableComponent,
    BreadcrumbComponent,
    ElementsOnTableComponent,
    PaginationComponent,
    SearchbarComponent
  ]
})
export class SharedModule { }

import { Component, SimpleChanges } from '@angular/core';
import { PartenariatService } from '../../../services/partenariat/partenariat.service';
import { SharedService } from '../../../services/shared/shared.service';
import { SharedModule } from '../../../modules/shared/shared.module';
import { MessageIn } from '../../../interfaces/MessageIn';
import { BreadcrumbComponent } from "../../../common/breadcrumb/breadcrumb.component";
import { getElementsOnPage } from '../../../helpers/functions/GetElementsOnPage';

@Component({
    selector: 'app-partenariat-messages',
    standalone: true,
    templateUrl: './partenariat-messages.component.html',
    styleUrl: './partenariat-messages.component.css',
    imports: [SharedModule, BreadcrumbComponent]
})
export class PartenariatMessagesComponent 
{
  title:string = "Partenariat - Messages" 
  pageSize:number = getElementsOnPage()
  pageIndex:number = 1
  idService!:number
  idUser!:number
  totalPages!:number
  totalResult!:number

  data_header_table:object = 
  {
    "badge":"",
    "date":"Date",
    "expediteur":"Expéditeur",
    "msg":"Intitulé message",
    "etat":"État",
    "documents":"Documents"
  }
  data_body_table: MessageIn[] = []
  isDataReady:boolean = false

  constructor
  (
      private partenariatService: PartenariatService,
      private sharedService: SharedService
  )
  {
    const id_service = this.sharedService.getIdService()
    this.idService = id_service
    const id_user = this.sharedService.getIdUser()
    this.idUser = id_user
    this.createPagination(this.pageSize)
  }

  ngOnInit()
  {
    this.getAllMessages()
  }

  ngOnChanges(changes: SimpleChanges) 
  {
    if ('search' in changes) 
    {
      this.getAllMessages()
    }
  }

  changePage(currentPage:number, pageSize:number=this.pageSize, changeDisplay:boolean=false)
  {  
    this.pageIndex = changeDisplay == false ? currentPage : 1 
    this.pageSize = pageSize
    this.getAllMessages()
  }

  async createPagination(pageSize:number)
  {
    (await
      this.sharedService.getInfoPagination("partenariat_messages", this.idService, this.idUser)).subscribe(data => {
      if(data)
      {
        this.totalResult = data[0]?.total_result
        this.totalPages = Math.ceil(this.totalResult / pageSize)
      }
    })
  }

  getAllMessages(search:string="")
  {
    this.partenariatService.getAllMessages(this.pageIndex, this.pageSize, this.idService, search).subscribe((data: MessageIn[]) => {
      this.data_body_table = data
      this.isDataReady = true
    })
  }
}

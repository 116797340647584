export function NoTagFichier(str: string): string 
{
    const map:any = 
    {
        'à': 'a', 'â': 'a', 'ä': 'a', 'á': 'a', 'å': 'a', 'ç': 'c', 'é': 'e',
        'è': 'e', 'ê': 'e', 'ë': 'e', 'î': 'i', 'ï': 'i', 'ô': 'o', 'ö': 'o',
        'ù': 'u', 'û': 'u', 'ü': 'u', 'ÿ': 'y', 'œ': 'oe', 'æ': 'ae', 'ñ': 'n',
        'À': 'A', 'Â': 'A', 'Ä': 'A', 'Á': 'A', 'Å': 'A', 'Ç': 'C', 'É': 'E',
        'È': 'E', 'Ê': 'E', 'Ë': 'E', 'Î': 'I', 'Ï': 'I', 'Ô': 'O', 'Ö': 'O',
        'Ù': 'U', 'Û': 'U', 'Ü': 'U', 'Ÿ': 'Y', 'Œ': 'OE', 'Æ': 'AE', 'Ñ': 'N',
        'ß': 'SS'
    }
    
    // Remplacer les caractères spéciaux en utilisant le mapping
    str = str.replaceAll(/[^a-zA-Z0-9-]/g, (match) => map[match] || ' ')
    
    // Remplacer les espaces par des tirets et supprimer les tirets multiples
    str = str.replaceAll(/\s+/g, '-').replaceAll(/-+/g, '-').trim()

    if(str.slice(-1) == '-') str = str.slice(0, -1)

    return str
}

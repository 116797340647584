<main class="main table">
  <app-breadcrumb [title]="title"></app-breadcrumb>

  <h3 class="grey-dark">{{ title }}</h3>

  <div class="mb-4">      
    <span class="small bold bg-blue-light-2 blue-dark-2 py-1 ps-2">
      Si vous souhaitez procéder à une mise à jour de vos interlocuteurs, veuillez formuler votre demande
    </span>
    <a class="small bold bg-blue-light-2 blue underline py-1 pe-2 cursor" (click)="goToContactPage()"> via ce lien</a>

    <span>
      <app-searchbar (onSearch)="getDataInterlocuteurs($event)"></app-searchbar>
    </span>
  </div>

  <div class="mat-elevation-z0 table-interlocuteurs">
      <table mat-table [dataSource]="dataSource" matSort>

        <ng-container matColumnDef="interlocuteur">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Interlocuteur</th>
          <td mat-cell *matCellDef="let element"> {{element.interlocuteur}} </td>
        </ng-container>
    
        <ng-container matColumnDef="fonction">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Fonction</th>
          <td mat-cell *matCellDef="let element"> {{element.fonction}} </td>
        </ng-container>
    
        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
          <td mat-cell *matCellDef="let element"> 
            <a href="mailto:{{element.email}}" *ngIf="element.email">
              {{element.email}}
            </a>
           </td>
        </ng-container>
    
        <ng-container matColumnDef="telephone">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Téléphone</th>
          <td mat-cell *matCellDef="let element"> 
            @if (element.telephone) 
            {
              {{ element.telephone | formatPhoneNumber }} 
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="mobile">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Mobile</th>
          <td mat-cell *matCellDef="let element">
            @if (element.mobile) 
            {
              {{ element.mobile | formatPhoneNumber }}
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="droits">
          <th mat-header-cell *matHeaderCellDef class="droits" style="background-color:#2794D8;color:white;text-align: center;">Droits</th>
          <td mat-cell *matCellDef="let element" style="background-color: #8ac5eb"> 
            <div *ngIf="element['gestion_dossier']">
              - Gestion dossiers
            </div>
            <div *ngIf="element['copie_message']">
              - En copie des messages
            </div>
            <div *ngIf="element['gestion_convention_facturation']">
              - Gestion convention facturation
            </div>
            <div *ngIf="element['saisie_donnees_abonnes']">
              - Saisie données abonnés
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef='a'>
          <th mat-header-cell *matHeaderCellDef></th>
        </ng-container>

        <ng-container matColumnDef='b'>
          <th mat-header-cell *matHeaderCellDef></th>
        </ng-container>

        <ng-container matColumnDef='c'>
          <th mat-header-cell *matHeaderCellDef></th>
        </ng-container>

        <ng-container matColumnDef='d'>
          <th mat-header-cell *matHeaderCellDef></th>
        </ng-container>

        <ng-container matColumnDef='e'>
          <th mat-header-cell *matHeaderCellDef></th>
        </ng-container>

        <ng-container matColumnDef="droits_access">
          <th mat-header-cell *matHeaderCellDef colspan="4" style="background-color:#2794D8;color: white;text-align:center;">Droits d'accès</th>
        </ng-container>

        <ng-container matColumnDef="gestion_dossiers">
          <!-- *
            *La propriété css est définie ici car au rechargement de la page, 
            *la propriété background-color s'applique à toute la colonne sauf au header de la table 
          *-->
          <th class="gestion_dossiers text-center px-0 py-0" mat-header-cell *matHeaderCellDef style="background-color: #8ac5eb">Gestion des dosssiers</th>
          <td mat-cell *matCellDef="let element"> {{element.gestion_dossier ? 'oui' : '-' }} </td>
        </ng-container>

        <ng-container matColumnDef="copie_messages">
          <th class="copie_messages text-center px-0 py-0" mat-header-cell *matHeaderCellDef style="background-color: #b9dcf3">En copie des messages</th>
          <td mat-cell *matCellDef="let element"> {{element.copie_message ? 'oui' : '-' }} </td>
        </ng-container>

        <ng-container matColumnDef="gestion_convention_facturation">
          <th class="gestion_convention_facturation text-center px-0 py-0" mat-header-cell *matHeaderCellDef style="background-color: #8ac5eb">Gestion convention & facturation</th>
          <td mat-cell *matCellDef="let element"> {{element.gestion_convention_facturation ? 'oui' : '-' }} </td>
        </ng-container>

        <ng-container matColumnDef="saisie_donnees">
          <th class="saisie_donnees text-center px-0 py-0" mat-header-cell *matHeaderCellDef style="background-color: #b9dcf3">Saisie des données abonnés</th>
          <td mat-cell *matCellDef="let element"> {{element.saisie_donnees_abonnes ? 'oui' : '-' }} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="['a','b','c','d','e','droits_access']" class="header-table-no-border header-droits-acces"></tr>
        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-table header-table-bold header-table-no-border header-table-border-bottom"></tr>

        <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="content-table"></tr>
      </table>
    
    </div>
</main>
  
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

// Custom validator function to confirm password
export function confirmPasswordValidator(passwordControlName: string, confirmPasswordControlName: string): ValidatorFn
{
  return (control: AbstractControl): ValidationErrors | null => {
    const password = control.get(passwordControlName)?.value
    const confirmPassword = control.get(confirmPasswordControlName)?.value

    // Compare the values
    if (password !== confirmPassword) 
    {
      // Return an error object with a key for the error and a value of true
      return { 'passwordMismatch': true }
    }

    // If validation passes, return null
    return null
  }
}
<main class="main table">
  <app-breadcrumb [title]="title"></app-breadcrumb>

  <section class="d-flex align-items-center justify-content-between mb-3 w-100">
    <h3 class="grey-dark">{{ title }}</h3>

    <div class="d-flex justify-content-between align-items-center">
      <app-elements-on-table 
          (onChangeItemsPerPage)="createPagination($event);pageSize = $event;changePage(pageIndex, $event, true)" [totalResult]="totalResult">
      </app-elements-on-table>

      <app-searchbar (onSearch)="getDataPartenariat($event)"></app-searchbar>
    </div>
  </section>


  @if ( !isDataReady ) 
  {
    <div class="d-flex justify-content-center">
      <mat-spinner></mat-spinner>
    </div>
  }
  @else
  { 
    @if(data_body_table.length == 0)
    {
      <div>
        <button class="bg-blue white no-border px-2 py-2 mb-1" [routerLink]="['/partenariat/details-dip/onglet_suivi']">Informations DIP / Convention</button>
        <p>
          <span class="small">En cliquant sur le bouton ci-dessus, vour pourrez télécharger votre DIP (demande d'intention partenariat) ou votre convention</span>
        </p>
      </div>
    }
    @else
    {
      <app-table [data_header_table]="data_header_table" [data_body_table]="data_body_table" [parent]="'partenariat-liste'" *ngIf="data_body_table"></app-table>
    }
  }
</main>

@if (totalPages && data_body_table && data_body_table.length > 0) 
{
    <app-pagination [currentPage]="pageIndex" (currentPageChange)="changePage($event)" [totalPages]="totalPages" [totalResult]="totalResult"></app-pagination>
}
